/* global alert _ */
import angular from 'angular'

import appWorkorderStock from './stock/stock.module'
import WorkordersController from './workorders.controller'
import WorkorderTemplatesController from './template/workorder-template-list.controller.js'
import WorkorderPriorityPullDialog from './template/workorder-priority-pull-dialog.controller.js'
import WorkorderTemplateShowController from './template/workorder-template.controller.js'
import WorkorderTemplateController from 'app/components/workorder-template/workorder-template.controller.js'
import WorkorderStatusController from './statuses/workorder-status.controller.js'
import CloneSessionController from './clone-session/clone-session.controller.js'

import {
  /* draggable, */
  WorkorderShowController,
  dynamic
} from './show/workorder.show.controller'

const MODULE_NAME = 'app.workorder'

angular
  .module(MODULE_NAME, ['ngSanitize', appWorkorderStock])
  .config(config)
  .controller('WorkorderShowController', WorkorderShowController)
  .controller('WorkorderTemplatesController', WorkorderTemplatesController)
  .controller('WorkorderPriorityPullDialog', WorkorderPriorityPullDialog)
  .controller(
    'WorkorderTemplateShowController',
    WorkorderTemplateShowController
  )
  .controller('WorkorderStatusController', WorkorderStatusController)

  .controller('CloneSessionController', CloneSessionController)
  .directive('dynamic', dynamic)
  .component('workorderTemplate', {
    template: require('app/components/workorder-template/workorder-template.html'),
    controller: WorkorderTemplateController,
    controllerAs: 'vm',
    bindings: {
      model: '=',
      groups: '=',
      hasSchedule: '=',
      hasNotifications: '=',
      immediately: '=',
      saveEvent: '<?'
    }
  })

/** @ngInject */
function config ($stateProvider, msApiProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.workorder', {
      abstract: true,
      url: '/workorder'
    })
    .state('app.workorder.list', {
      url: '/list/?:type/?:filter/?:baseFilter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: WorkordersController,
          controllerAs: 'vm'
        }
      },
      resolve: {
        locations: ($rootScope, Location) => {
          $rootScope.locations = []
          const promise = Location.find({
            filter: {
              deleted: true
            }
          }).$promise
          promise.then(locations => {
            $rootScope.locations = locations
          })
          return promise
        },
        ResolvedUsers: UserModel =>
          UserModel.find({
            filter: { fields: { id: true, displayName: true } }
          }).$promise,

        ResolvedWorkorderStatuses: WorkorderStatus =>
          WorkorderStatus.find({
            filter: {
              deleted: true,
              fields: { id: true, name: true, color: true, deletedAt: true }
            }
          }).$promise,
        ResolvedWorkflowTypes: WorkflowType =>
          WorkflowType.find({
            filter: {
              fields: { id: true, name: true, deletedAt: true }
            }
          }).$promise
      },
      bodyClass: 'workorder'
    })
    .state('app.workorder.create', {
      url: '/edit/',
      views: {
        'content@app': {
          template: require('./show/show.html')
        }
      },
      bodyClass: 'workorder'
    })
    .state('app.workorder.workflowSession', {
      url: '/show/:id/:sessionIds',
      views: {
        'content@app': {
          template: '<div flex><loading-screen></loading-screen></div>',
          controller: /** @ngInject */ (
            $state,
            $stateParams,
            ResolvedSessionIds
          ) => {
            $state.go(
              'app.workflow.session.show',
              {
                recordId: $stateParams.recordId,
                workflowSessionIds: ResolvedSessionIds,
                preview: false
              },
              {
                location: 'replace'
              }
            )
          },
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedSessionIds: function (
          $rootScope,
          $mdToast,
          $state,
          $stateParams,
          WorkflowSessionItem,
          $translate
        ) {
          return new Promise(async (resolve, reject) => {
            try {
              const res = await WorkflowSessionItem.applyWorkflow({
                workorderId: $stateParams.id,
                workflowSessionItemIds: $stateParams.sessionIds
              }).$promise
              resolve(res)
            } catch (err) {
              console.error(err)
              const {
                data: {
                  error: { code, message }
                }
              } = err
              reject(err)
              if (code === 'MODEL_NOT_FOUND') {
                // workflow associated with group and user doesn't have permission
                $state.go('app.404')
              } else {
                let newMessage = message
                if (code === 'START_SESSION_QUANTITY_ZERO') {
                  newMessage = $translate.instant(
                    'WO.ERRORS.START_SESSION_QUANTITY_ZERO'
                  )
                }
                $mdToast.show(
                  $mdToast.nextplus({
                    position: $rootScope.toastLocation,
                    parent: 'body',
                    theme: 'error-toast',
                    hideDelay: 3500
                  })
                )
                $mdToast.updateTextContent(newMessage)
              }
            }
          })
        }
      }
    })
    .state('app.workorder.show', {
      url: '/show/:id/?:serial',
      views: {
        'content@app': {
          template: require('./show/show.html'),
          controller: 'WorkorderShowController as vm'
        }
      },
      bodyClass: 'workorder workorder-show',
      resolve: {
        ResolvedLocations: Location => {
          return Location.find({
            fields: {
              name: true,
              id: true
            }
          })
        },
        WorkorderWithStock: function (Workorder, $stateParams, $state) {
          const workorder = Workorder.get({
            id: $stateParams.id,
            fetchRemoteWorkflow: true,
            fetchERPWorkorder: true
          }).$promise
          workorder.catch(err => {
            alert(`Error: ${err.data.error.message}`)
          })
          workorder.then(workorderData => {
            if ($stateParams.serial) {
              let session
              if (workorderData.source === 'local') {
                session = workorderData.sessions.find(session =>
                  session.serials.includes($stateParams.serial)
                )
              } else {
                const stock = workorderData.stocks.find(
                  stock => stock.serial === $stateParams.serial
                )
                session = stock.workflowSessionItem
              }
              if (session && workorderData.workflowId && !session.lock) {
                $state.go('app.workorder.workflowSession', {
                  id: workorderData.id,
                  sessionIds: [session.id]
                })
              }
            }
          })

          return workorder
        },
        ResolvedWorkorderStatuses: WorkorderStatus =>
          WorkorderStatus.find({
            filter: {
              where: {},
              fields: {
                id: true,
                color: true,
                isChangeReasonRequired: true,
                changeReasonFields: true
              }
            }
          }).$promise
      }
    })
    .state('app.workorder.templates', {
      url: '/templates/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkorderTemplatesController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        isDialog: () => false
      },
      bodyClass: 'workorder-template'
    })
    .state('app.workorder.template', {
      url: '/template/?:id',
      views: {
        'content@app': {
          template: require('./template/workorder-template.html'),
          controller: 'WorkorderTemplateShowController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedWorkorderTemplate: (WorkorderTemplate, $stateParams) => {
          if (_.isUndefined($stateParams.id) || $stateParams.id === '') {
            return null
          }
          return WorkorderTemplate.findOne({
            filter: { where: { id: $stateParams.id } }
          }).$promise
        },
        ResolvedGroups: Group => Group.find({}).$promise
      },
      bodyClass: 'workorder-template'
    })
    .state('app.workorder.workorder-statuses', {
      url: '/workorder-statuses',
      bodyClass: 'workorder-statuses',

      resolve: {
        ResolvedWorkorderStatus: WorkorderStatus =>
          WorkorderStatus.find({
            filter: {
              fields: {
                id: true,
                name: true,
                color: true,
                systemDefault: true,
                onCreateDefault: true,
                onStartDefault: true,
                onEndDefault: true,
                statusType: true,
                isChangeReasonRequired: true,
                changeReasonFields: true,
                transitionToPermitRoleIds: true,
                transitionToPermitCertificateIds: true
              }
            }
          }).$promise,
        ResolvedRoles: Role =>
          Role.find({
            filter: {
              fields: {
                id: true,
                name: true,
                nicename: true,
                disableUse: true
              }
            }
          }).$promise,
        ResolvedCertificates: Certificate =>
          Certificate.find({
            filter: {
              fields: {
                id: true,
                name: true
              }
            }
          }).$promise
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkorderStatusController',
          controllerAs: 'vm'
        }
      }
    })

    .state('app.workorder.workorder-clone-session', {
      url: '/workorder-clone-session',
      bodyClass: 'workorder-clone-session',
      resolve: {},
      views: {
        'content@app': {
          template: require('./clone-session/clone-session.template.html'),
          controller: 'CloneSessionController',
          controllerAs: 'vm'
        }
      }
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.workorder', {
    title: 'WO.WORKORDERS',
    icon: 'icon-clipboard-list',
    weight: 10,
    acls: [{ model: 'Workorder', method: 'find' }]
  })
  msNavigationServiceProvider.saveItem('apps.workorder.mine', {
    title: 'WO.MINE_WORKORDERS',
    state: 'app.workorder.list({type:"mine"})',
    acls: [{ model: 'Workorder', method: 'find' }]
  })
  msNavigationServiceProvider.saveItem('apps.workorder.opened', {
    title: 'WO.OPEN_WORKORDERS',
    state: 'app.workorder.list({type:"opened"})',
    acls: [{ model: 'Workorder', method: 'find' }]
  })
  msNavigationServiceProvider.saveItem('apps.workorder.qa', {
    title: 'WO.QA_WORKORDERS',
    hidden: true,
    state: 'app.workorder.list({type:"qa"})',
    acls: [{ model: 'Workorder', method: 'find' }]
  })
  msNavigationServiceProvider.saveItem('apps.workorder.closed', {
    title: 'WO.CLOSE_WORKORDERS',
    state: 'app.workorder.list({type:"closed"})',
    acls: [{ model: 'Workorder', method: 'find' }]
  })
  msNavigationServiceProvider.saveItem('apps.modeling.workorder', {
    title: 'WO.WORKORDER',
    icon: 'icon-clipboard-list',
    weight: 20
  })
  msNavigationServiceProvider.saveItem('apps.modeling.workorder.templates', {
    title: 'WO.TEMPLATE.TEMPLATES',
    state: 'app.workorder.templates',
    icon: 'icon-format-align-left',
    weight: 10,
    acls: [{ model: 'WorkorderTemplate', method: 'create' }]
  })

  msNavigationServiceProvider.saveItem(
    'apps.modeling.workorder.workorder-statuses',
    {
      title: 'WO.STATUSES_MENU',
      state: 'app.workorder.workorder-statuses',
      icon: 'icon-information',
      weight: 20,
      acls: [{ model: 'WorkorderStatus', method: 'create' }]
    }
  )
  // msNavigationServiceProvider.saveItem('apps.workorder.time-log-report', {
  //   title: 'TIME_LOGGING.TITLE',
  //   hidden: false,
  //   weight: 40,
  //   acls: [{ model: 'TimeLogReport', method: 'find' }]
  // })
  // msNavigationServiceProvider.saveItem(
  //   'apps.workorder.time-log-report.my-records',
  //   {
  //     title: 'TIME_LOGGING.MY_RECORDS',
  //     state: 'app.workorder.my-records',
  //     hidden: false,
  //     weight: 10,
  //     acls: [{ model: 'TimeLogReport', method: 'find' }]
  //   }
  // )
  // msNavigationServiceProvider.saveItem(
  //   'apps.workorder.time-log-report.my-employee-records',
  //   {
  //     title: 'TIME_LOGGING.MY_EMPLOYEE_RECORDS',
  //     state: 'app.workorder.my-employee-records',
  //     hidden: false,
  //     weight: 20,
  //     acls: [{ model: 'TimeLogReport', method: 'find' }]
  //   }
  // )
  msNavigationServiceProvider.saveItem(
    'apps.modeling.workorder.workorder-clone-session',
    {
      title: 'WO.CLONE_SESSION.TITLE',
      state: 'app.workorder.workorder-clone-session',
      icon: 'icon-content-copy',
      weight: 30
      // acls: [{ model: 'WorkorderStatus', method: 'create' }]
    }
  )
  msNavigationServiceProvider.saveItem(
    'apps.modeling.workorder.failureReports',
    {
      title: 'WO.TEMPLATE.DEFECT_REPORTS',
      state: 'app.reports.form',
      icon: 'icon-file-document-alert',
      stateParams: { id: 'f9762684-ffba-43d1-9d28-f2654bd31971' },
      weight: 40
    }
  )
}

export default MODULE_NAME

/* global angular */

/** @ngInject */
function StockServiceCall (
  $rootScope,
  $scope,
  $mdDialog,
  $stateParams,
  Stock,
  $translate,
  ResolvedAvailableWarehouses,
  PartAssembly
) {
  const vm = this
  $scope.inProgress = false

  $scope.warehousesByKey = {}
  $scope.warehouses = ResolvedAvailableWarehouses.map(warehouse => {
    const key = `${warehouse.warehouseName} - ${warehouse.warehouseLocation}`
    $scope.warehousesByKey[key] = warehouse
    return {
      id: key,
      name: $translate.instant(
        'PRIORITY.SETTINGS.WAREHOUSES_OPTIONS.WAREHOUSE_NAME_AND_CODE',
        {
          warehouseName: warehouse.warehouseDes,
          warehouseCode: warehouse.warehouseName
        }
      ),
      displayName: $translate.instant(
        'PRIORITY.SETTINGS.WAREHOUSES_OPTIONS.WAREHOUSE_LOCATION',
        { warehouseLocation: warehouse.warehouseLocation }
      )
    }
  })
  $scope.actions = [
    {
      name: 'הרכבה',
      value: 'add'
    },
    {
      name: 'החלפה (הרכבה + פירוק)',
      value: 'replace'
    },
    {
      name: 'פירוק',
      value: 'remove'
    }
  ]

  vm.formWizard = $scope.formWizard = {}

  $scope.headerOptions = {
    icon: 'icon-cog',
    template: require('app/templates/headers/simple.html'),
    title: 'החלפת מכשירים במכשיר אב',
    fabButton: {}
  }

  $scope.msWizard = {
    selectedIndex: 0
  }

  $scope.sendForm = async wizardData => {
    $scope.inProgress = $rootScope.loadingProgress = true
    let res
    let partErrors = null
    const warehouse = $scope.warehousesByKey[wizardData.warehouse]
    wizardData.warehouseName = warehouse.warehouseName
    wizardData.warehouseLocation = warehouse.warehouseLocation
    try {
      const { serviceCall, errors } = await Stock.serviceCall(wizardData)
        .$promise
      res = serviceCall
      partErrors = errors
      console.log('res', res)
      $scope.wizardModel.wizard = {}
      $scope.selectedIndex = 0
    } catch (err) {
      res = err.data.error
    }
    $mdDialog.show({
      /** @ngInject */
      controller: ($scope, $mdDialog, res) => {
        $scope.res = res
        $scope.partErrors = partErrors
        $scope.header = 'תוצאות ממשק'
        $scope.cancel = () => $mdDialog.cancel()
      },
      multiple: true,
      template: require('./stock.service.call.dialog.html'),
      parent: angular.element(document.body),
      targetEvent: '',
      locals: {
        res
      },
      clickOutsideToClose: true
    })
    $scope.inProgress = $rootScope.loadingProgress = false
  }

  $scope.wizardModel = {
    wizard: {}
  }

  if ($stateParams.parent) {
    const { sku, serial } = $stateParams.parent
    $scope.wizardModel.wizard.parentSku = sku
    $scope.wizardModel.wizard.parentSerial = serial
  }

  $scope.wizardFields = [
    {
      key: 'wizard',
      type: 'wizardGroup',
      templateOptions: {
        class: '',
        selectedIndex: $scope.selectedIndex,
        nextButtonText: $translate.instant('WO.WIZARD.NEXT'),
        backButtonText: $translate.instant('WO.WIZARD.BACK'),
        lastStageButtonText: $translate.instant('WO.WIZARD.SEND'),
        busy: $scope.inProgress,
        callback: $scope.sendForm,
        fields: [
          // stage 1
          {
            wrapper: 'wizardItem',
            templateOptions: {
              label: $translate.instant('WO.WIZARD.SELECT_ACTION'),
              fieldGroup: [
                {
                  key: 'action',
                  type: 'radio',
                  className: 'layout-row',
                  templateOptions: {
                    type: 'text',
                    required: true,
                    options: $scope.actions
                  }
                }
              ]
            }
          },
          // stage 2
          {
            wrapper: 'wizardItem',
            templateOptions: {
              label: $translate.instant('WO.WIZARD.MASTER_DEVICE_DETAILS'),
              fieldGroup: [
                {
                  key: 'parentSku',
                  type: 'autocomplete',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    label: $translate.instant('WO.WIZARD.MASTER_SKU'),
                    queryMethod: PartAssembly.find,
                    mapObject: {
                      id: 'number',
                      name: 'number'
                    },
                    baseFilterObject: {
                      where: {},
                      fields: {
                        number: true,
                        name: true
                      }
                    }
                  }
                },
                {
                  key: 'parentSerial',
                  type: 'input',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    type: 'text',
                    label: $translate.instant('WO.WIZARD.DEVICE_SERIAL')
                  }
                }
              ]
            }
          },
          // stage 3
          {
            wrapper: 'wizardItem',
            templateOptions: {
              label: $translate.instant('WO.WIZARD.PRODUCT_DETAILS'),
              fieldGroup: [
                {
                  key: 'childrenSku',
                  type: 'autocomplete',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    label: $translate.instant('WO.WIZARD.SUB_DEVICE_SKU'),
                    queryMethod: PartAssembly.find,
                    mapObject: {
                      id: 'number',
                      name: 'number'
                    },
                    baseFilterObject: {
                      where: {},
                      fields: {
                        number: true,
                        name: true
                      }
                    }
                  }
                },
                {
                  key: 'removeChildrenSerial',
                  type: 'input',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    type: 'text',
                    label: $translate.instant(
                      'WO.WIZARD.DISASSEMBLY_DEVICE_NUMBER'
                    )
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.action === 'add'
                  }
                },
                {
                  key: 'addChildrenSerial',
                  type: 'input',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    type: 'text',
                    label: $translate.instant(
                      'WO.WIZARD.ASSEMBLY_DEVICE_NUMBER'
                    )
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.action === 'remove'
                  }
                }
                // addChildrenSerial
              ]
            }
          },
          // stage 4
          {
            wrapper: 'wizardItem',
            templateOptions: {
              label: $translate.instant('WO.WIZARD.SELECT_WAREHOUSE'),
              fieldGroup: [
                {
                  key: 'warehouse',
                  type: 'selectWithSearch',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    label: $translate.instant('WO.WIZARD.SELECT_WAREHOUSE'),
                    options: $scope.warehouses
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
}

module.exports = StockServiceCall

/* global angular _ moment kendo */
import priorityConstants from '../priorityConstants'
import WorkflowKitController from '../../workflow/show/dialogs/workflow.kit.controller.js'
import { openConfiguration } from '../../workflow/services/WorkflowSessionHelper.js'
import PrintDialogController from '../print/print.dialog.controller'
import ReleaseLogsDialogController from '../../workflow/session/templates/release-logs.dialog.controller'
import WorkorderStatusFieldsController from '../statuses/workorder-status-fields-dialog.controller.js'
import FrozenWorkorderDialogController from '../statuses/frozen-workorder-dialog.controller.js'
import KitModificationDialogController from '../kit-modification/kit-modification-dialog.controller.js'
import WorkflowFormListController from '../../workflow/show/dialogs/workflow.form.list.controller'
import workorderSubformsDialogController from '../subforms/workorder-subforms.dialog.controller.js'
const {
  FAILURE_FORM_DETAILS
} = require('../../../../../../../common/constants/form-constants.json')
/** @ngInject */
function dynamic ($compile) {
  return {
    restrict: 'A',
    replace: true,
    link: function (scope, ele, attrs) {
      scope.$watch(attrs.dynamic, function (html) {
        ele.html(html)
        $compile(ele.contents())(scope)
      })
    }
  }
}

/** @ngInject */

const debug = require('debug')('nextplus:workorder')

/** @ngInject */
function WorkorderShowController (
  WorkorderWithStock,
  $translate,
  $rootScope,
  $scope,
  $state,
  $mdDialog,
  $mdToast,
  $timeout,
  $window,
  Workorder,
  $stateParams,
  PermissionUtils,
  KendoGridHelper,
  nextplusSocket,
  FormlyHelper,
  EventService,
  DialogService,
  Page,
  Group,
  Stock,
  WorkflowSessionItem,
  ResolvedLocations,
  WorkorderStatus,
  ResolvedWorkorderStatuses,
  FieldUtilsService,
  Form,
  UserModel,
  FormUtils,
  ViewsService,
  htmlWork,
  PartViewHelper,
  DateTimeFormatService,
  WorkorderUtils
) {
  const vm = this
  let units = []
  const devices = []
  let sessions = []
  let stocks = []
  let kitItems = []
  vm.devicesById = {}
  vm.unitsById = {}

  $scope.statusById = _.keyBy(ResolvedWorkorderStatuses, 'id')
  $scope.hasEnterSessionPermissions = PermissionUtils.isPermit(
    'WorkflowSessionItem',
    'enterSession'
  )
  $scope.hasSaveWorkorderPermissions = PermissionUtils.isPermit(
    'Workorder',
    'saveLocalWorkorder'
  )
  const hasCreateWorkflowPermissions = PermissionUtils.isPermit(
    'Workflow',
    'patchAttributes'
  )
  let workorderGroups = null
  const errorToast = $mdToast.nextplus({
    position: $rootScope.toastLocation,
    parent: '#content',
    theme: 'error-toast',
    hideDelay: 3500
  })

  let counter = 0

  const dateFormatter = function dateFormatter (value) {
    return value === '' || _.isNil(value)
      ? '--'
      : DateTimeFormatService.formatDateTime(value, 'date')
  }

  const dateTimeFormatter = function dateTimeFormatter (value) {
    return value === '' || _.isNil(value)
      ? '--'
      : DateTimeFormatService.formatDateTime(value, 'dateTime')
  }

  const durationFormatter = function durationFormatter (value) {
    if (value === '--') return '--'
    const parts = []
    const duration = moment.duration(value)
    if (!duration || duration.toISOString() === 'P0D') return '--'
    if (duration.years() >= 1) {
      const years = Math.floor(duration.years())
      parts.push(
        years > 1
          ? `${years} ${$translate.instant('COMMON.YEARS')}`
          : `${years} ${$translate.instant('COMMON.YEAR')}`
      )
    }
    if (duration.months() >= 1) {
      const months = Math.floor(duration.months())
      parts.push(
        months > 1
          ? `${months} ${$translate.instant('COMMON.MONTHS')}`
          : `${months} ${$translate.instant('COMMON.MONTH')}`
      )
    }
    if (duration.days() >= 1) {
      const days = Math.floor(duration.days())
      parts.push(
        days > 1
          ? `${days} ${$translate.instant('COMMON.DAYS')}`
          : `${days} ${$translate.instant('COMMON.DAY')}`
      )
    }
    if (duration.hours() >= 1) {
      const hours = Math.floor(duration.hours())
      parts.push(
        hours > 1
          ? `${hours} ${$translate.instant('COMMON.HOURS')}`
          : `${hours} ${$translate.instant('COMMON.HOUR')}`
      )
    }
    if (duration.minutes() >= 1) {
      const minutes = Math.floor(duration.minutes())
      parts.push(
        minutes > 1
          ? `${minutes} ${$translate.instant('COMMON.MINUTES')}`
          : `${minutes} ${$translate.instant('COMMON.MINUTE')}`
      )
    }
    if (duration.seconds() >= 1) {
      const seconds = Math.floor(duration.seconds())
      parts.push(
        seconds > 1
          ? `${seconds} ${$translate.instant('COMMON.SECONDS')}`
          : `${seconds} ${$translate.instant('COMMON.SECOND')}`
      )
    }
    return parts.join(', ')
  }
  const getChangeLogFields = async function getChangeLogFields () {
    $scope.fieldsByChangeLogRecord = []
    const fieldIds = new Set()
    vm.workorder.changeLog.forEach(async changeLog => {
      if (changeLog.fields && changeLog.fields.length > 0) {
        changeLog.fields.forEach(field => {
          fieldIds.add(field.fieldId)
        })
      }
    })
    if (fieldIds.size > 0) {
      $scope.changeLogUniqueFields = await WorkorderStatus.getFieldsByFieldIds({
        fieldIds: [...fieldIds]
      })
    }
  }

  const isFormClosed = function isFormClosed (formData) {
    const closeStatusNames = formData.statuses
      .filter(status => status.status === 'close')
      .map(status => status.name)

    const translatedValues = closeStatusNames.map(status =>
      $translate.instant(status)
    )

    return (
      closeStatusNames.includes(formData.status) ||
      translatedValues.includes(formData.status)
    )
  }
  const showReleaseLogs = ($scope.showReleaseLogs = function showReleaseLogs () {
    $mdDialog.show({
      controller: ReleaseLogsDialogController,
      template: require('../../workflow/session/templates/release-logs.dialog.html'),
      parent: angular.element(document.body),
      locals: {
        workflow: $scope.workflow
      },
      resolve: {
        workflowReleaseLogs: function (WorkflowSessionItem) {
          return WorkflowSessionItem.getWorkflowReleaseLogs({
            workflowId:
              vm.workorder.workflowId || vm.workorder.suggestedWorkflowId
          }).$promise
        }
      },
      multiple: true,
      fullscreen: true,
      escapeToClose: true,
      clickOutsideToClose: false
    })
  })

  $scope.printWorkorder = function printWorkorder () {
    return showPrintDialog({ Workorder: [vm.workorder.id] })
  }

  const printSession = ($scope.printSession = function printSession (
    sessionIds
  ) {
    return showPrintDialog({ Session: sessionIds })
  })

  const showPrintDialog = ($scope.showPrintDialog = function showPrintDialog (
    printModels
  ) {
    $mdDialog.show({
      controller: PrintDialogController,
      template: require('../print/print.dialog.html'),
      parent: angular.element(document.body),
      locals: { printModels },
      resolve: {
        labels: function (PriorityLabel) {
          return PriorityLabel.find({
            filter: { fields: { id: true, models: true, name: true } }
          }).$promise
        }
      },
      multiple: true,
      fullscreen: false,
      escapeToClose: true,
      clickOutsideToClose: false
    })
  })

  const handleForms = function handleForms (formDatas) {
    if (!formDatas) {
      return {}
    }
    const formStatsGroupByFormId = {}
    formDatas.forEach(formData => {
      if (!formStatsGroupByFormId[formData.formId]) {
        formStatsGroupByFormId[formData.formId] = {
          hasStatus: false,
          total: 0,
          closed: 0
        }
      }
      formStatsGroupByFormId[formData.formId].total++
      formStatsGroupByFormId[formData.formId].hasStatus = formData.hasStatus
      if (formData.hasStatus && isFormClosed(formData)) {
        formStatsGroupByFormId[formData.formId].closed++
      }
    })
    return formStatsGroupByFormId
  }

  function fixCustomFields (data) {
    data.forEach(item => {
      if (item.fieldGroup && item.fieldGroup.length) {
        fixCustomFields(item.fieldGroup)
      }
    })

    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].key && data[i].key.startsWith('C_')) {
        if (vm.workorder.source === 'priority') {
          let customFieldKey = 'customFieldMapping'
          if (vm.workorder.externalType === 'serviceCall') {
            customFieldKey = 'customFieldServiceCallMapping'
          }
          // Remove custom fields from form by key by the priority mapping
          const mappingFromSettings = _.get(
            $rootScope.appSettings,
            'prioritySettings.' + customFieldKey
          )
          if (mappingFromSettings) {
            const customFieldDeclaration = mappingFromSettings.find(
              customFieldDeclaration =>
                customFieldDeclaration.customFieldKey === data[i].key
            )
            if (typeof customFieldDeclaration === 'undefined') {
              data.splice(i, 1)
            } else if (customFieldDeclaration.readWriteMode === 'READ') {
              data[i].templateOptions.readonly = true
            } else {
              data[i].templateOptions.onChange = function (value, options) {
                vm.saveTrigger = true
              }
            }
          }
        } else if (vm.workorder.source === 'local') {
          data[i].templateOptions.onChange = function () {
            if ($scope.isLocalWorkorder) {
              vm.saveTrigger = true
            }
          }
        }
      }
    }

    return data
  }

  $scope.hasProgressColumn = function hasProgressColumn () {
    return units.some(
      unit => unit && unit.progress !== -1 && unit.progress !== 0
    )
  }
  function updateUnitsCount (updateArray, type) {
    // create a set of ids from the updateArray for faster lookup
    let idSet = new Set()
    if (type === 'stock') {
      idSet = new Set(updateArray.map(item => item.workflowSessionItemId))
    } else {
      idSet = new Set(updateArray.map(item => item.id))
    }

    // filter the mainArray to remove any items with ids not in the updateArray
    units = units.filter(item => idSet.has(item.id))
  }

  const createSerialsHtml = function createSerialsHtml (serials) {
    const generatedSerials = vm.workorder.generatedSerials
      ? vm.workorder.generatedSerials.map(option => option.serial)
      : []
    const serialsHtmlArray = []
    const notInGeneratedSerials = []
    serials.forEach(serial => {
      let html = `<span class="serial">${htmlWork.htmlEncode(serial)}`
      if (generatedSerials?.length > 0 && !generatedSerials.includes(serial)) {
        notInGeneratedSerials.push(serial)
      }
      html += '</span>'
      serialsHtmlArray.push(html)
    })
    let serialsHtml = serialsHtmlArray.join(', ')
    if (notInGeneratedSerials.length > 0) {
      serialsHtml += `<md-icon class="s16" style="color: red;margin-bottom: 18px;" md-font-icon="icon-alert-circle">
                  <md-tooltip>${$translate.instant(
                    'WO.MISMATCH_SERIALS_WARNING',
                    {
                      serials: notInGeneratedSerials.join(', ')
                    }
                  )}</md-tooltip>
                  </md-icon>`
    }
    return serialsHtml
  }
  const createUnits = function createUnits () {
    updateUnitsCount(sessions, 'session')
    for (let i = 0; i < sessions.length; i++) {
      const session = sessions[i]
      const serials = session.serials ? session.serials.join(',') : ''
      const unitObject = {
        id: session.id,
        stockIds: session.stockIds,
        stocks: session.stocks,
        lock: session.lock,
        indicator: session.indicator,
        serials,
        serialsHtml: createSerialsHtml(session.serials),
        serialsAndIndicator: [serials, session.indicator],
        status: $translate.instant(session.status),
        statusCode: session.status,
        formDataGrouped: session.formDataGrouped,
        progress: session.progress,
        kitStatus: null,
        priority: null,
        currentAction: null,
        nextLocationId: session.nextLocationId,
        productionStatus: session.validQuantity > 0 ? 'valid' : 'invalid',
        activeUsers: session.activeUsers || [],
        configuration: session.configuration,
        quantity: session.quantity,
        validQuantity: session.validQuantity,
        invalidQuantity: session.invalidQuantity,
        end: session.end,
        name: session.name
      }
      if (!vm.unitsById[session.id]) {
        units.push(unitObject)
      } else {
        Object.keys(unitObject).forEach(key => {
          units[i][key] = unitObject[key]
        })
      }
    }
    vm.unitsById = _.keyBy(units, 'id')
  }

  const createUnitsTable = async function createUnitsTable () {
    const tableColumns = [
      // CHECKBOX
      {
        uniqueId: 'bb76ab99-d9b8-47ac-a19f-b212a3b1aab2',
        field: 'checkbox',
        width: '60px',
        locked: true,
        resizable: false,
        translateCode: 'WO.CHECKBOX',
        headerTemplate: `<div layout="column">
                          <div layout="row" layout-align="center center">
                            <md-checkbox
                              ng-model="vm.allValidUnitsSelected"
                              ng-change="vm.checkAllUnitsByType('valid')"
                            >
                            </md-checkbox>
                          </div>
                        </div>`,
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                      <md-checkbox
                        ng-if="$root.appSettings.allowMultiUsersInSession || !vm.unitsById['${data.id}'].lock"
                        ng-model="vm.selectedValidUnit['${data.id}']"
                        ng-change= "vm.checkAllUnitsSelectedByType('valid')"
                      >
                      </md-checkbox>
                      <md-icon
                        ng-if="!$root.appSettings.allowMultiUsersInSession && vm.unitsById['${data.id}'].lock"
                        md-font-icon="icon-lock"
                        class="s22"
                      ></md-icon>
                  </div>
                  `
        }
      },
      // PLAY
      {
        uniqueId: '5fca6dce-1bec-467b-8960-be05161dcedc',
        field: 'play',
        width: '70px',
        locked: true,
        resizable: false,
        translateCode: 'WO.PLAY',
        headerTemplate: `<span></span>`,
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          // play button
          let html = `<div layout="row" layout-align="center center">`
          const buttonAction =
            data.statusCode === 'WF.NEW' &&
            parseInt(data.quantity) > 1 &&
            vm.workorder.createStock
              ? `ng-click="selectQuantityToStart('${data.id}')"`
              : `ui-sref="app.workorder.workflowSession({id:vm.workorder.id, sessionIds: ['${data.id}']})" ui-sref-opts="{notify: false}"`
          html += `<md-button
            ng-if="
              hasEnterSessionPermissions &&
              ($root.appSettings.allowMultiUsersInSession ||
                (!$root.appSettings.allowMultiUsersInSession &&
                  !vm.unitsById['${data.id}'].lock.id))
            "
            class="md-fab md-mini md-primary"
            style="padding-top: auto;"
            ${buttonAction}
          >
            <md-icon
              style="cursor: pointer"
              md-font-icon="icon-play"
              class="s22 green-500-fg"
            ></md-icon>
          </md-button>`
          // lock icon
          html += `<md-icon
          ng-if="
            !$root.appSettings.allowMultiUsersInSession &&
            vm.unitsById['${data.id}'].lock.id
          "
          md-font-icon="icon-lock"
          class="s22 red-500-fg"
        >
          <md-tooltip>
            <span
              translate-values="{user: vm.unitsById['${data.id}'].lock.user}"
              translate="WF.LOCKED"
            ></span>
          </md-tooltip>
        </md-icon>
        </div>`
          return html
        }
      },
      // NAME
      {
        uniqueId: 'c1238d0a-4d0c-4a12-83d9-581d016c79b5',
        field: 'name',
        type: 'string',
        translateCode: 'WO.SESSION_NAME',
        filterable: true,
        sortable: true,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                      <span ng-if="vm.unitsById['${data.id}'].name" ng-bind="vm.unitsById['${data.id}'].name"></span>
                      <span ng-if="!vm.unitsById['${data.id}'].name"> -- </span>
                      <md-button
                        ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                        ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                        ng-click="editSessionName('${data.id}')"
                      >
                        <md-icon
                          md-font-icon="icon-pencil"
                          class="s22 "
                        ></md-icon>
                      </md-button>
                  </div>
                  `
        }
      }
    ]
    if (vm.workorder.isSerial) {
      // SERIAL AND INDICATOR
      tableColumns.push({
        uniqueId: 'a1b2c3d4-e5f6-4a7b-8c9d-0e1f2a3b4c5d',
        field: 'serialsAndIndicator',
        type: 'string',
        translateCode: 'WO.serial_number_and_indicator',
        filterable: true,
        sortable: true,
        trustedTemplate: data => {
          const buttonAction =
            data.statusCode === 'WF.NEW' &&
            data.quantity !== 1 &&
            vm.workorder.createStock
              ? `ng-click="selectQuantityToStart('${data.id}')"`
              : `ui-sref="app.workorder.workflowSession({id:vm.workorder.id, sessionIds: ['${data.id}']})" ui-sref-opts="{notify: false}"`
          return `
                    <div layout="row" layout-align="start start">
                      <a
                      ng-if="hasEnterSessionPermissions &&
                      ($root.appSettings.allowMultiUsersInSession ||
                      (!$root.appSettings.allowMultiUsersInSession &&
                        !vm.unitsById['${data.id}'].lock.id))"
                      ${buttonAction}
                      >
                      (${htmlWork.htmlEncode(vm.unitsById[data.id].indicator)})
                      ${vm.unitsById[data.id].serialsHtml} </a>
                      <span
                      ng-if="!hasEnterSessionPermissions ||
                      (!$root.appSettings.allowMultiUsersInSession &&
                        vm.unitsById['${data.id}'].lock.id)"
                      >
                      (${htmlWork.htmlEncode(vm.unitsById[data.id].indicator)})
                      ${vm.unitsById[data.id].serialsHtml} </span>
                    </div>
                  `
        }
      })
    } else {
      // INDICATOR
      tableColumns.push({
        uniqueId: 'abbde3a5-0a74-4c49-b44c-fcd6fb831bde',
        field: 'indicator',
        type: 'string',
        title: '#',
        filterable: true,
        sortable: true,
        trustedTemplate: data => {
          return `
                    <div layout="row" layout-align="start start">
                      <a
                      ng-if="hasEnterSessionPermissions &&
                      ($root.appSettings.allowMultiUsersInSession ||
                      (!$root.appSettings.allowMultiUsersInSession &&
                        !vm.unitsById['${data.id}'].lock.id))"
                      ui-sref="app.workorder.workflowSession({id:vm.workorder.id,sessionIds: ['${
                        data.id
                      }']})"
                      ui-sref-opts="{notify: false}"
                      >(${htmlWork.htmlEncode(
                        vm.unitsById[data.id].indicator
                      )})</a>
                      <span
                      ng-if="!hasEnterSessionPermissions ||
                      (!$root.appSettings.allowMultiUsersInSession &&
                        vm.unitsById['${data.id}'].lock.id)"
                      >(${htmlWork.htmlEncode(
                        vm.unitsById[data.id].indicator
                      )})</span>
                    </div>
                  `
        }
      })
    }
    const validStatuses = [
      $translate.instant('WF.NEW'),
      $translate.instant('WF.IN_PROGRESS'),
      $translate.instant('WF.FINISH')
    ]

    tableColumns.push(
      // STATUS
      {
        uniqueId: 'e6f7g8h9-i0j1-4k5l-6m7n-8o9p0q1r2s3',
        field: 'status',
        type: 'string',
        translateCode: 'WO.status',
        values: validStatuses,
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataSource: new kendo.data.DataSource({
                  data: validStatuses
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        trustedTemplate: data => {
          let html = `<div layout="column" layout-align="center center">
                      <span ng-bind="vm.unitsById['${data.id}'].status"></span>`
          html += `<div class="forms">`
          Object.keys(data.formDataGrouped.toJSON()).forEach(formId => {
            html += `<div class="form">`
            if (vm.formById[formId].icon) {
              html += `<md-icon
                        md-font-icon="${htmlWork.escapeHTMLQuotes(
                          vm.formById[formId].icon
                        )}"
                        class="s22"
                        ></md-icon>`
            }
            html += `<span>${htmlWork.htmlEncode(
              vm.formById[formId].name
            )}</span>`
            if (
              !vm.formById[formId].singleton &&
              data.formDataGrouped[formId].hasStatus
            ) {
              html += `<span>(${htmlWork.htmlEncode(
                data.formDataGrouped[formId].closed
              )}/${htmlWork.htmlEncode(
                data.formDataGrouped[formId].total
              )})</span>`
            }
            if (
              !vm.formById[formId].singleton &&
              !data.formDataGrouped[formId].hasStatus
            ) {
              html += `<span>(${htmlWork.htmlEncode(
                data.formDataGrouped[formId].total
              )})</span>`
            }
            html += `</div>`
          })
          html += `</div></div>`
          return html
        }
      }
    )
    if ($scope.hasProgressColumn()) {
      // PROGRESS
      tableColumns.push({
        uniqueId: 't4u3v2w1-x0y9-8z7a-6b5c-4d3e2f1g0h9',
        field: 'progress',
        type: 'number',
        translateCode: 'WO.progress',
        filterable: false,
        sortable: true,
        trustedTemplate: data => {
          if (data.progress === -1 || data.progress === 0) {
            return ''
          } else {
            return `
                <div layout="row" layout-align="center center">
                  <div
                    class="md-progress-circular-wrapper"
                  >
                    <md-progress-circular
                      md-mode="determinate"
                      value="{{ ${htmlWork.htmlEncode(data.progress)} }}"
                    >
                    </md-progress-circular>
                    <span class="progress-circular-text">
                      {{ ${htmlWork.htmlEncode(data.progress)} }}%
                    </span>
                  </div>
                </div>`
          }
        }
      })
    }

    tableColumns.push(
      ...[
        // STATION
        {
          uniqueId: 'b3c4d5e6-f7g8-9h0i-1j2k-3l4m5n6o7p8',
          field: 'station',
          translateCode: 'WO.TABLE.STATION',
          filterable: true,
          sortable: true,
          trustedTemplate: data => {
            return `
                  <div layout="row" layout-align="center center">
                    <span
                      ng-if="vm.unitsById['${data.id}'].nextLocationId"
                      ng-bind="vm.locationsById[vm.unitsById['${data.id}'].nextLocationId].name">
                    </span>
                  </div>`
          }
        }
      ]
    )
    tableColumns.push(
      // PRODUCTION_STATUS
      {
        uniqueId: 'q9r8s7t6-u5v4-3w2x-1y0z-9a8b7c6d5e4',
        field: 'productionStatus',
        translateCode: 'WO.TABLE.PRODUCTION_STATUS',
        values: ['valid', 'invalid'],
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataSource: new kendo.data.DataSource({
                  data: ['valid', 'invalid']
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        trustedTemplate: data => {
          return `
            <div layout="row" layout-align="center center">
              <md-select
                ng-model="vm.unitsById['${data.id}'].productionStatus"
                ng-change="changeProductionStatus('${data.id}', vm.unitsById['${data.id}'].productionStatus, true)"
                style="width: 100px"
              >
                <md-option
                  value="valid"
                  translate="WF.SESSION_PRODUCTION_STATUSES.VALID"
                ></md-option>
                <md-option
                  value="invalid"
                  translate="WF.SESSION_PRODUCTION_STATUSES.INVALID"
                ></md-option>
              </md-select>
            </div>`
        }
      }
    )
    tableColumns.push(
      ...[
        // QUANTITY
        {
          uniqueId: 'q9r8s7t6-u5v4-3w2x-1y0z-9a8b7c6d5e4',
          field: 'quantity',
          type: 'number',
          translateCode: 'WO.TABLE.QUANTITY',
          filterable: true,
          sortable: true,
          trustedTemplate: data => {
            return `
                  <div layout="row" layout-align="center center">
                    <span ng-bind="vm.unitsById['${data.id}'].quantity"></span>
                  </div>`
          }
        }
      ]
    )
    tableColumns.push(
      ...[
        // ACTIVE USERS
        {
          uniqueId: 'f3g4h5i6-j7k8-9l0m-1n2o-3p4q5r6s7t8',
          field: 'activeUsers',
          translateCode: 'WO.ACTIVE_USERS',
          filterable: false,
          sortable: true,
          trustedTemplate: data => {
            return `<span ng-if="vm.unitsById['${data.id}'].activeUsers">
                  <users-avatar
                    max-users="3"
                    name-property="'displayName'"
                    users="vm.unitsById['${data.id}'].activeUsers"
                  ></users-avatar>
                </span>
                <span ng-if="!vm.unitsById['${data.id}'].activeUsers">--</span>`
          }
        },
        // ACTIONS
        {
          uniqueId: 'db1ca4b1-4c6b-4d8a-bf12-12ee0f6e73dc',
          field: 'actions',
          translateCode: 'WF.ACTIONS',
          filterable: false,
          sortable: false,
          trustedTemplate: data => {
            let html = `<div layout="column">
                        <div layout="row" layout-align="center center">`
            if ($rootScope.appSettings.priorityLabels.Session > 0) {
              html += `<md-button
                      ng-click="printSession([session.id])"
                      ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                      ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                    >
                      <md-icon
                        style="cursor: pointer"
                        md-font-icon="icon-printer"
                        class="s22"
                      ></md-icon>
                    </md-button>`
            }
            // eye icon - session report
            html += `<md-button
                  ng-if="vm.unitsById['${data.id}'].statusCode !== 'WF.NEW'"
                  ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                  ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                  style="margin-inline-start: 1rem; margin-inline-end: 0"
                  ui-sref="app.workflow.workflow-session-item-report({workflowSessionId: '${data.id}'})"
                >
                  <md-icon
                    md-font-icon="icon-eye"
                    class="s22"
                  ></md-icon>
                </md-button>`
            // session configuration
            html += `<md-button
                    ng-if="
                      vm.unitsById['${data.id}'].configuration &&
                      vm.unitsById['${data.id}'].configuration.length > 0
                      "
                    ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                    ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                    style="padding: 0; margin: 0"
                    ng-click="vm.openConfiguration('${data.id}')"
                  >
                    <md-icon md-font-icon="icon-cog" class="s16"></md-icon>
                  </md-button>`
            // delete button
            if (PermissionUtils.isPermit('Workorder', 'deleteLocalUnit')) {
              html += `<md-button
                      ng-show="
                         vm.unitsById['${data.id}'].quantity === 0 || (!vm.workorder.createStock && vm.quantityDifference > 0)
                      "
                      ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                      ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                      ng-click="deleteSession('${data.id}')"
                    >
                      <md-icon
                        md-font-icon="icon-delete"
                        class="s22 "
                      ></md-icon>
                    </md-button>`
            }
            if (PermissionUtils.isPermit('Workorder', 'splitSession')) {
              html += `<md-button
                          ng-show="!vm.workorder.isSerial || (vm.workorder.isSerial && vm.unitsById['${data.id}'].quantity > 1)
                      "
                      ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                      ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                      ng-click="splitSession('${data.id}')"
                    >
                      <md-icon
                        md-font-icon="icon-arrow-split-vertical"
                        class="s22 "
                      ></md-icon>
                      <md-tooltip>
                        <span translate="WO.SPLIT_SESSION"></span>
                      </md-tooltip>
                    </md-button>`
            }
            if (PermissionUtils.isPermit('Workorder', 'resetSession')) {
              html += `<md-button
                          ng-show="
                          vm.unitsById['${data.id}'].statusCode !== 'WF.NEW'
                      "
                      ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                      ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                      ng-click="resetSession('${data.id}')"
                    >
                      <md-icon
                        md-font-icon="icon-restart"
                        class="s22 "
                      ></md-icon>
                      <md-tooltip>
                        <span translate="WO.RESET_SESSION"></span>
                      </md-tooltip>
                    </md-button>`
            }

            html += `</div></div>`
            return html
          }
        }
      ]
    )

    const baseFilter = {}
    const validUnitsStateName = 'app.workorder.valid-units'
    const invalidUnitsStateName = 'app.workorder.invalid-units'

    const validUnitsViewDetails = getViewDetails(
      tableColumns.filter(column => column.field !== 'productionStatus'),
      baseFilter,
      validUnitsStateName
    )

    const invalidUnitsViewDetails = getViewDetails(
      tableColumns,
      baseFilter,
      invalidUnitsStateName
    )

    $scope.validDefaultTableSetup = {
      stateName: validUnitsStateName,
      ignoreParams: true,
      data: units.filter(unit => unit.validQuantity === unit.quantity),
      serverSide: false,
      autoSize: false,
      pageSize: 10,
      cleanBaseFilter: baseFilter,
      baseFilter: validUnitsViewDetails.newBaseFilter,
      selectedViewId: validUnitsViewDetails.selectedViewId,
      columns: validUnitsViewDetails.columns
    }

    const invalidCheckboxColumnUpdate = {
      headerTemplate: `<div layout="column">
                          <div layout="row" layout-align="center center">
                            <md-checkbox
                              ng-model="vm.allInvalidUnitsSelected"
                              ng-change="vm.checkAllUnitsByType('invalid')"
                            >
                            </md-checkbox>
                          </div>
                        </div>`,
      trustedTemplate: data => {
        return `<div layout="row" layout-align="center center">
                      <md-checkbox
                        ng-model="vm.selectedInvalidUnit['${data.id}']"
                        ng-change= "vm.checkAllUnitsSelectedByType('invalid')"
                      >
                      </md-checkbox>
                  </div>
                  `
      }
    }
    const invalidUnitsColumns = invalidUnitsViewDetails.columns.map(column => {
      if (column.field === 'checkbox') {
        return {
          ...column,
          ...invalidCheckboxColumnUpdate
        }
      } else {
        return column
      }
    })
    $scope.invalidUnits = units.filter(unit => unit.invalidQuantity > 0)

    $scope.invalidDefaultTableSetup = {
      ...$scope.validDefaultTableSetup,
      stateName: invalidUnitsStateName,
      data: $scope.invalidUnits,
      baseFilter: invalidUnitsViewDetails.newBaseFilter,
      selectedViewId: invalidUnitsViewDetails.selectedViewId,
      columns: invalidUnitsColumns
    }

    const validDefaultTableToolbarSetup = {
      stateName: validUnitsStateName,
      columns: validUnitsViewDetails.columns,
      currentColumnIds: validUnitsViewDetails.columns.map(c => c.uniqueId),
      filters: validUnitsViewDetails.filters,
      selectedViewId: validUnitsViewDetails.selectedViewId,
      title: $scope.title
    }
    const invalidDefaultTableToolbarSetup = {
      stateName: invalidUnitsStateName,
      columns: invalidUnitsColumns,
      currentColumnIds: invalidUnitsColumns.map(c => c.uniqueId),
      filters: invalidUnitsViewDetails.filters,
      selectedViewId: invalidUnitsViewDetails.selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    vm.validUnitsKendoGrid = await KendoGridHelper.GridInstance(
      $scope.validDefaultTableSetup,
      $scope,
      validUnitsViewDetails.columns
    )

    vm.validUnitsTableToolbar = await ViewsService.GridToolBarInstance(
      validDefaultTableToolbarSetup,
      vm.validUnitsKendoGrid,
      $scope
    )

    vm.invalidUnitsKendoGrid = await KendoGridHelper.GridInstance(
      $scope.invalidDefaultTableSetup,
      $scope,
      invalidUnitsViewDetails.columns
    )

    vm.invalidUnitsTableToolbar = await ViewsService.GridToolBarInstance(
      invalidDefaultTableToolbarSetup,
      vm.invalidUnitsKendoGrid,
      $scope
    )

    $scope.$applyAsync()
    setTimeout(() => {
      vm.validUnitsKendoGrid.instance.resize(true)
    }, 1000)
  }

  const getViewDetails = function getViewDetails (
    tableColumns,
    baseFilter,
    stateName
  ) {
    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      tableColumns,
      stateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      stateName
    )

    return {
      columns,
      selectedViewId,
      newBaseFilter,
      filters
    }
  }

  $scope.invalidUnitsCollapsibleOpen = false
  $scope.toggleCollapsible = function toggleCollapsible () {
    $scope.invalidUnitsCollapsibleOpen = !$scope.invalidUnitsCollapsibleOpen
    if ($scope.invalidUnitsCollapsibleOpen) {
      setImmediate(() => {
        vm.invalidUnitsKendoGrid.instance.resize(true)
      })
    }
  }

  $scope.resetSession = async function resetSession (sessionId) {
    const extraFields = [
      {
        key: 'keepWorkflowVersion',
        type: 'checkbox',
        className: 'layout-row',
        templateOptions: {
          label: $translate.instant('WO.KEEP_WORKFLOW_VERSION')
        }
      }
    ]
    const extraFieldsObj = {
      extraFieldsFormly: extraFields,
      extraFieldsModel: {
        keepWorkflowVersion: false
      }
    }
    DialogService.resetDialog(
      $translate.instant(
        'WO.RESET_SESSION_DIALOG',
        {},
        null,
        null,
        'sceParameters'
      ),
      'reset',
      extraFieldsObj
    ).then(
      async function (res) {
        $rootScope.loadingProgress = true
        return Workorder.resetSession({
          sessionId,
          workorderId: vm.workorder.id,
          keepWorkflowVersion: res.extraFieldsModel.keepWorkflowVersion
        })
          .$promise.then(function (res) {
            $window.location.reload()
          })
          .catch(function (err) {
            $rootScope.loadingProgress = false
            sessionErrorHandler(err)
          })
      },
      function () {}
    )
  }

  $scope.selectQuantityToStart = async function selectQuantityToStart (
    sessionId
  ) {
    const goToSessionId = await $scope.splitSession(sessionId, true)
    if (goToSessionId) {
      $state.go('app.workorder.workflowSession', {
        id: vm.workorder.id,
        sessionIds: [goToSessionId]
      })
    }
  }
  $scope.splitSession = async function splitSession (
    sessionId,
    isSessionStart = false
  ) {
    const session = vm.unitsById[sessionId]
    return WorkorderUtils.splitSession(
      session,
      vm.workorder,
      isSessionStart,
      sessionErrorHandler
    )
      .then(function (sessionId) {
        if (isSessionStart) {
          return sessionId
        } else {
          $state.go(
            'app.workorder.show',
            { id: vm.workorder.id },
            { reload: true }
          )
        }
      })
      .catch(function (err) {
        sessionErrorHandler(err)
      })
  }

  $scope.changeStockSession = async function changeStockSession (
    stockIds,
    action,
    sourceSessionId
  ) {
    let sessionId = null
    if (action !== 'clone') {
      sessionId = action
    }
    const stocks = []
    stockIds.forEach(stockId => {
      const stock = vm.stocksById[stockId]
      stocks.push({
        id: stock.id,
        quantity: stock.quantity,
        newStockQuantity: stock.quantity,
        formsAction: 'move'
      })
    })
    if (!stocks.length) {
      return
    }

    const obj = {
      workorderId: vm.workorder.id,
      stocks,
      isStocks: true
    }
    if (sessionId) {
      obj.sessionId = sessionId
      obj.indicator = vm.unitsById[sessionId].indicator
    }
    if (action === 'clone') {
      obj.sourceSessionId = sourceSessionId
    }
    return Workorder.updateStocks(obj)
      .$promise.then(function (res) {
        $state.go(
          'app.workorder.show',
          { id: vm.workorder.id },
          { reload: true }
        )
      })
      .catch(function (err) {
        sessionErrorHandler(err)
      })
  }

  const sessionErrorHandler = function sessionErrorHandler (err) {
    console.error(err)
    const error = [
      'SOURCE_SESSION_ALREADY_FINISHED',
      'TARGET_SESSION_ALREADY_FINISHED',
      'SESSION_ALREADY_FINISHED',
      'WORKORDER_ALREADY_FINISHED',
      'STOCK_HAS_ATTACHED_SERIALS',
      'STOCK_HAS_STOCK_ITEMS',
      'INVALID_DEVICE_TO_VALID_SESSION',
      'VALID_DEVICE_TO_INVALID_SESSION',
      'NEW_QUANTITY_IS_BIGGER_THAN_CURRENT',
      'STOCK_HAS_REPORTED_ACTIONS',
      'STOCK_HAS_REPORTED_ACTIONS_RESET',
      'QUANTITY_INTEGER_UNIT_EACH',
      'QUANTITY_INTEGER_BULK_PRODUCTION',
      'QUANTITY_INTEGER_SUB_PARTS',
      'STOCK_HAS_FINISHED_PRODUCTION'
    ].includes(err.data.error.code)
      ? `WO.ERRORS.${err.data.error.code}`
      : `WO.ERRORS.SESSION_CHANGE_FAILED`
    $rootScope.showErrorToast(
      $translate.instant(
        error,
        err?.data?.error?.details ? err.data.error.details : {}
      ),
      true
    )
    const stockIds = Object.keys(vm.stocksById)
    stockIds.forEach(stockId => {
      vm.stocksById[stockId].workflowSessionItemId =
        vm.stocksById[stockId].workflowSessionItem.id
    })
  }

  const createDevices = function createDevices () {
    if (stocks?.length > 0) {
      for (let i = 0; i < stocks.length; i++) {
        const stock = stocks[i]
        const unit = vm.unitsById[stock.workflowSessionItemId]
        const serial = stock.serial || ''
        const deviceObject = {
          id: stock.id,
          sessionId: stock.workflowSessionItemId,
          indicator: stock.indicator,
          serial,
          serialHtml: createSerialsHtml([serial]),
          kitStatus: stock.kitStatus,
          priority: stock.priority,
          currentAction: stock.currentAction?.workCenter
            ? stock.currentAction.workCenter
            : null,
          productionStatus: stock.productionStatus,
          quantity: stock.quantity,
          activeUsersNow: unit?.activeUsers?.length > 0
        }
        if (!vm.devicesById[stock.id]) {
          devices.push(deviceObject)
        } else {
          Object.keys(deviceObject).forEach(key => {
            devices[i][key] = deviceObject[key]
          })
        }
      }
      vm.devicesById = _.keyBy(devices, 'id')
    }
  }
  const createSessionOptions = function createSessionOptions () {
    vm.sessionOptions = [
      {
        text: $translate.instant('WO.NEW_DUPLICATED_SESSION'),
        value: 'clone'
      },
      ...units.map(unit => {
        let name = unit.indicator
        if (unit.name) {
          name = unit.indicator + ' (' + unit.name + ')'
        }
        return {
          text: name,
          value: unit.id
        }
      })
    ]
  }
  const createDevicesTable = async function createDevicesTable () {
    createSessionOptions()

    const tableColumns = [
      // CHECKBOX
      {
        uniqueId: 'bb76ab99-d9b8-47ac-a19f-b212a3b1aab2',
        field: 'checkbox',
        width: '60px',
        locked: true,
        resizable: false,
        translateCode: 'WO.CHECKBOX',
        headerTemplate: `<div layout="column">
                          <div layout="row" layout-align="center center">
                            <md-checkbox
                              ng-model="vm.allDevicesSelected"
                              ng-change="vm.checkAllDevices()"
                            >
                            </md-checkbox>
                          </div>
                        </div>`,
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                      <md-checkbox
                        ng-if="!vm.devicesById['${data.id}'].activeUsersNow"
                        ng-model="vm.selectedDevice['${data.id}']"
                        ng-change="vm.checkAllDevicesSelected()"
                      >
                      </md-checkbox>
                          <md-icon
                            ng-if="vm.devicesById['${data.id}'].activeUsersNow"
                            md-font-icon="icon-lock"
                            class="s22"
                          >
                          </md-icon>
                  </div>
                  `
        }
      },
      // INDICATOR (sessionId)
      {
        uniqueId: '22668b60-a1df-4f1a-b395-ef97277de40f',
        field: 'sessionId',
        type: 'string',
        translateCode: 'WO.indicator',
        values: vm.sessionOptions,
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'text',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: vm.sessionOptions
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        trustedTemplate: data => {
          return `
                        <div layout="row" layout-align="center center">
                          <md-input-container>
                            <md-select
                              ng-disabled="vm.devicesById['${
                                data.id
                              }'].activeUsersNow"
                              ng-change="changeStockSession(['${htmlWork.escapeHTMLQuotes(
                                data.id
                              )}'], vm.devicesById['${
            data.id
          }'].sessionId, vm.stocksById['${data.id}'].workflowSessionItemId)"
                              md-on-close="clearSearchTerm()"
                              ng-model="vm.devicesById['${data.id}'].sessionId"
                            >
                              <md-select-header class="selectboxWithSearch">
                                <input
                                  onkeydown="event.stopPropagation();"
                                  type="text"
                                  ng-keyup="search($event);"
                                />
                                <md-icon
                                  ng-hide="searchTerm !== ''"
                                  class="s26"
                                  md-font-icon="icon-magnify"
                                ></md-icon>
                                <md-icon
                                  ng-hide="searchTerm === ''"
                                  ng-click="clearSearchTerm()"
                                  class="s26"
                                  md-font-icon="icon-close"
                                ></md-icon>
                              </md-select-header>
                              <md-option
                                ng-value="option.value"
                                ng-repeat="option in vm.sessionOptions"
                              >
                                {{ option.text }}
                              </md-option>
                            </md-select>
                          </md-input-container>
                        </div>`
        }
      },
      // SERIAL
      {
        uniqueId: '1a2b3c4d-5e6f-7g8h-9i0j-1k2l3m4n5o6p',
        field: 'serial',
        type: 'string',
        translateCode: 'WO.serial_number',
        filterable: true,
        sortable: true,
        trustedTemplate: data => {
          if (vm.devicesById[data.id].serial) {
            return `
                    <div layout="row" layout-align="start start">
                      <span>${vm.devicesById[data.id].serialHtml}</span></div>`
          } else {
            return '--'
          }
        }
      }
    ]
    if (!$scope.isLocalWorkorder) {
      if (vm.workorder.source !== 'manager') {
        // KIT STATUS
        tableColumns.push({
          uniqueId: 'i9j8k7l6-m5n4-3o2p-1q0r-9s8t7u6v5w4',
          field: 'kitStatus',
          type: 'boolean',
          translateCode: 'WO.TABLE.KIT_STATUS',
          filterable: true,
          sortable: true,
          trustedTemplate: data => {
            let html = `
                      <div layout="row" layout-align="center center">
                        <md-button
                        class="md-icon-button"
                        ng-click="vm.showKit('${htmlWork.escapeHTMLQuotes(
                          data.id
                        )}')"
                        >`
            if (data.kitStatus) {
              html += `<md-icon
                      md-font-icon="icon-checkbox-blank-circle"
                      class="s24 green-500-fg"
                    ></md-icon>`
            } else {
              html += `<md-icon
                      md-font-icon="icon-checkbox-blank-circle"
                      class="s24 red-500-fg"
                    ></md-icon>`
            }
            html += `</md-button></div>`
            return html
          }
        })
      }
      // PRIORITY
      tableColumns.push(
        ...[
          {
            uniqueId: 'x3y4z5a6-b7c8-9d0e-1f2g-3h4i5j6k7l8',
            field: 'priority',
            type: 'number',
            translateCode: 'WO.PRIORITY',
            values: vm.priorityFieldOptions.map(o => ({
              text: o.name,
              value: o.id
            })),
            filterable: {
              mode: 'row',
              cell: {
                showOperators: false,
                operator: 'eq',
                suggestionOperator: 'eq',
                template: function (args) {
                  args.element.kendoDropDownList({
                    filter: 'contains',
                    autoBind: false,
                    dataTextField: 'name',
                    dataValueField: 'id',
                    dataSource: new kendo.data.DataSource({
                      data: vm.priorityFieldOptions
                    }),
                    valuePrimitive: true
                  })
                }
              }
            },
            sortable: true,
            trustedTemplate: data => {
              return `
                        <div layout="row" layout-align="center center">
                          <md-input-container>
                            <md-select
                              ng-disabled="!PermissionUtils.isPermit('Stock','updatePriority')"
                              ng-change="changeUnitPriority('${htmlWork.escapeHTMLQuotes(
                                data.id
                              )}',vm.devicesById['${data.id}'].priority,'${
                data.sessionId
              }' )"
                              md-on-close="clearSearchTerm()"
                              ng-model="vm.devicesById['${data.id}'].priority"
                            >
                              <md-select-header class="selectboxWithSearch">
                                <input
                                  onkeydown="event.stopPropagation();"
                                  type="text"
                                  ng-keyup="search($event);"
                                />
                                <md-icon
                                  ng-hide="searchTerm !== ''"
                                  class="s26"
                                  md-font-icon="icon-magnify"
                                ></md-icon>
                                <md-icon
                                  ng-hide="searchTerm === ''"
                                  ng-click="clearSearchTerm()"
                                  class="s26"
                                  md-font-icon="icon-close"
                                ></md-icon>
                              </md-select-header>
                              <md-option
                                ng-value="option.id"
                                ng-repeat="option in vm.priorityFieldOptions"
                              >
                                {{ option.name }}
                              </md-option>
                            </md-select>
                          </md-input-container>
                        </div>`
            }
          },
          // LOCATION
          {
            uniqueId: 'm9n8o7p6-q5r4-3s2t-1u0v-9w8x7y6z5a4',
            field: 'currentAction',
            type: 'string',
            translateCode: 'WO.TABLE.LOCATION',
            filterable: true,
            sortable: true,
            trustedTemplate: data => {
              return `
              <div layout="row" layout-align="center center">
                <span ng-bind="vm.devicesById['${data.id}'].currentAction"></span>
              </div>`
            }
          }
        ]
      )
    }
    tableColumns.push(
      ...[
        // PRODUCTION_STATUS
        {
          uniqueId: 'q9r8s7t6-u5v4-3w2x-1y0z-9a8b7c6d5e4',
          field: 'productionStatus',
          translateCode: 'WO.TABLE.PRODUCTION_STATUS',
          values: ['valid', 'invalid'],
          filterable: {
            mode: 'row',
            cell: {
              showOperators: false,
              operator: 'eq',
              suggestionOperator: 'eq',
              template: function (args) {
                args.element.kendoDropDownList({
                  filter: 'contains',
                  autoBind: false,
                  dataSource: new kendo.data.DataSource({
                    data: ['valid', 'invalid']
                  }),
                  valuePrimitive: true
                })
              }
            }
          },
          sortable: true,
          trustedTemplate: data => {
            return `
              <div layout="row" layout-align="center center">
                <md-select
                  ng-model="vm.devicesById['${data.id}'].productionStatus"
                  ng-change="changeProductionStatus('${data.id}', vm.devicesById['${data.id}'].productionStatus)"
                  style="width: 100px"
                >
                  <md-option
                    value="valid"
                    translate="WF.SESSION_PRODUCTION_STATUSES.VALID"
                  ></md-option>
                  <md-option
                    value="invalid"
                    translate="WF.SESSION_PRODUCTION_STATUSES.INVALID"
                  ></md-option>
                </md-select>
              </div>`
          }
        },
        // QUANTITY
        {
          uniqueId: '7481a958-d20c-4ff8-ac5b-748ccca05577',
          field: 'quantity',
          translateCode: 'WO.TABLE.QUANTITY',
          filterable: true,
          sortable: true,
          trustedTemplate: data => {
            return `
                <div layout="row" layout-align="center center">
                  <span ng-bind="vm.devicesById['${data.id}'].quantity"></span>
                  <md-button
                    ng-if="!vm.workorder.isSerial"
                    ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                    ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                    ng-click="editStockQuantity('${data.id}')"
                  >
                    <md-icon
                      md-font-icon="icon-pencil"
                      class="s22 "
                    ></md-icon>
                  </md-button>
                </div>`
          }
        },
        // ACTIONS
        {
          uniqueId: 'db1ca4b1-4c6b-4d8a-bf12-12ee0f6e73dc',
          field: 'actions',
          translateCode: 'WF.ACTIONS',
          filterable: false,
          sortable: false,
          trustedTemplate: data => {
            let html = `<div layout="column">
                      <div layout="row" layout-align="center center">`
            // delete button
            if (PermissionUtils.isPermit('Stock', 'deleteStock')) {
              html += `<md-button
                  ng-if="!vm.workorder.isSerial || (vm.quantityDifference > 0 && vm.workorder.isSerial)"
                  ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                  ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                  ng-click="deleteStock('${data.id}')"
                >
                  <md-icon
                    md-font-icon="icon-delete"
                    class="s22 "
                  ></md-icon>
                </md-button>`
            }
            html += `</div></div>`
            return html
          }
        }
      ]
    )
    const baseFilter = {}
    const devicesStateName = 'app.workorder.devices'

    const { columns, selectedViewId, newBaseFilter, filters } = getViewDetails(
      tableColumns,
      baseFilter,
      devicesStateName
    )

    $scope.defaultTableSetup = {
      stateName: devicesStateName,
      ignoreParams: true,
      data: devices,
      serverSide: false,
      autoSize: false,
      pageSize: 10,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns
    }

    const defaultTableToolbarSetup = {
      stateName: 'app.workorder.devices',
      columns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    vm.devicesKendoGrid = await KendoGridHelper.GridInstance(
      $scope.defaultTableSetup,
      $scope,
      columns
    )

    vm.devicesTableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      vm.devicesKendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  const initScreen = async function initScreen () {
    $scope.PermissionUtils = PermissionUtils
    $scope.isAbleToEditStock = PermissionUtils.isPermit(
      'Workorder',
      'updateStocks'
    )
    vm.initialExpectedDate = 0
    vm.locationsById = _.keyBy(ResolvedLocations, 'id')
    sessions = WorkorderWithStock.sessions
    stocks = WorkorderWithStock.stocks
    vm.sessionsById = _.keyBy(sessions, 'id')
    vm.stocksById = _.keyBy(stocks, 'id')
    kitItems = WorkorderWithStock.kitItems
    vm.kitItemsById = _.keyBy(kitItems, 'id')
    vm.workorder = _.omit(WorkorderWithStock, [
      'stocks',
      'sessions',
      'stockIds',
      'sessionIds',
      'kitItems'
    ])

    const plainDescription = $window.DOMPurify.sanitize(
      vm.workorder.description
    )

    if (plainDescription) {
      const descriptionIframe = document.getElementById('workorder-description')
      descriptionIframe.srcdoc = vm.workorder.description
      descriptionIframe.style.display = 'block'
    }

    if (vm.workorder.statusType === 'WO.STATUSES.FROZEN') {
      $mdDialog.show({
        controller: FrozenWorkorderDialogController,
        template: require('../statuses/frozen-workorder-dialog.template.html'),
        parent: angular.element(document.body),
        multiple: true,
        escapeToClose: false,
        clickOutsideToClose: true
      })
    }
    // $scope.showReleaseLogs()
    $scope.title = `${$translate.instant('WO.WORKORDER')} ${
      vm.workorder.workorderNumber
    }`
    Page.setTitleText($scope.title)
    $scope.isLocalWorkorder = vm.workorder.source === 'local'
    $scope.isManagerWorkorder = vm.workorder.source === 'manager'
    vm.formById = _.keyBy(vm.workorder.forms, 'id')
    vm.saveTrigger = false
    vm.revMatch = false
    vm.hasNewVersion = true
    vm.workflowFound = null
    vm.selectedSession = {}
    vm.selectedValidUnit = {}
    vm.selectedInvalidUnit = {}
    vm.selectedDevice = {}
    vm.quantityDifference = 0
    getChangeLogFields()
    if (Array.isArray(sessions) && sessions.length) {
      sessions.forEach(session => {
        session.formDataGrouped = handleForms(session.formData)
        if ($rootScope?.appSettings?.allowMultiUsersInSession) {
          session.lock = null
        }
      })
    }
    if ($scope.isLocalWorkorder && vm.workorder.expectedDate) {
      vm.initialExpectedDate = new Date(vm.workorder.expectedDate)
      vm.initialExpectedDate.setSeconds(0, 0)
      vm.initialExpectedDate = vm.initialExpectedDate.getTime()
    }
    if ($scope.isManagerWorkorder) {
      vm.autoRefreshWorkorder = false
    } else {
      vm.autoRefreshWorkorder = true
    }

    EventService.saveEvent({
      type: 'view',
      model: 'Workorder',
      modelId: vm.workorder.id
    })

    vm.priorityFieldOptions = []

    priorityConstants.forEach(constant => {
      vm.priorityFieldOptions.push({
        id: constant.value,
        name: $translate.instant(constant.string),
        icon: constant.icon,
        color: constant.color
      })
    })

    const statusField = {
      key: 'statusId',
      type: 'modelSelect',
      className: 'flex-xs-100 flex-33 m-5 layout-row',
      templateOptions: {
        label: $translate.instant('WO.STATUS'),
        className: 'flex',
        optionsIcon: 'icon-checkbox-blank-circle',
        findMethod: WorkorderStatus.find,
        mapObject: { id: 'id', name: 'name', color: 'color' },
        baseFilterObject: {
          where: {
            or: [
              { statusType: { neq: 'WO.STATUSES.NEW' } },
              { id: vm.workorder.statusId }
            ]
          },
          fields: {
            name: true,
            color: true,
            id: true
          }
        },
        onChange: async function (value, options) {
          if (options.formControl.$viewValue !== options.initialValue) {
            try {
              const status = $scope.statusById[value]
              await WorkorderStatus.checkPermission({
                statusId: value
              }).$promise
              if (status.isChangeReasonRequired) {
                const result = await openFieldsDialog(
                  status.changeReasonFields,
                  value
                )
                if (!result) {
                  options.resetModel()
                } else {
                  options.updateInitialValue()
                  $state.go(
                    'app.workorder.show',
                    { id: vm.workorder.id },
                    { reload: true }
                  )
                }
              } else {
                await WorkorderStatus.changeStatus({
                  workorderId: vm.workorder.id,
                  statusId: value
                }).$promise
                $state.go(
                  'app.workorder.show',
                  { id: vm.workorder.id },
                  { reload: true }
                )
                options.updateInitialValue()
              }
            } catch (err) {
              console.error(err)
              if (err.data.error.details) {
                const mdToast = $mdToast.nextplus({
                  position: $rootScope.toastLocation,
                  parent: 'body',
                  theme: 'error-toast',
                  hideDelay: 6000
                })
                if (err.data.error.details.statusName) {
                  $mdToast.updateTextContent(
                    $translate.instant(
                      `WO.${err.data.error.code}`,
                      err.data.error.details
                    )
                  )
                }
                $mdToast.show(mdToast)
                console.log('xxx')
              }
              options.resetModel()
            }
          }
        }
      }
    }
    const assignedUsers = {
      key: 'assignedUsers',
      type: 'selectUser',
      className: 'flex-xs-100 flex-33 m-5 layout-row priority-input ',
      templateOptions: {
        multiple: true,
        showConstants: false,
        showAssignee: false,
        disabled: !PermissionUtils.isPermit('Workorder', 'assign'),
        field_class: 'priority-input',
        searchPlaceholder: 'Search...',
        label: $translate.instant('WO.ASSIGNED_USERS'),
        onChange: function (users) {
          // eslint-disable-next-line
          Workorder.assign({
            workorderId: vm.workorder.id,
            userIds: users,
            notifyUsers: true
          }).$promise
        }
      }
    }

    const priorityField = {
      key: 'priority',
      type: 'selectWithSearch',
      className: 'flex-xs-100 flex-33 m-5 layout-row',
      templateOptions: {
        multiple: false,
        disabled: !PermissionUtils.isPermit('Workorder', 'updatePriority'),
        field_class: 'priority-input',
        input_container_class: 'input-container-class',
        searchPlaceholder: 'Search...',
        label: $translate.instant('WO.PRIORITY'),
        options: vm.priorityFieldOptions,
        onChange: function (priority, options) {
          // eslint-disable-next-line
          Workorder.updatePriority({ workorderId: vm.workorder.id, priority })
            .$promise
        }
      }
    }
    createUnits()
    createDevices()
    await createUnitsTable()
    await createDevicesTable()
    compareQuantities()
    const genericRowClass = 'display-flex layout-xs-column layout-md-row'
    const generalFields = {
      wrapper: 'collapsibleItem',
      templateOptions: {
        label: $translate.instant('WF.PROPERTIES.WORKFLOW.GENERAL')
      },
      fieldGroup: [
        {
          className: genericRowClass,
          fieldGroup: [
            // REV
            {
              key: 'productRev',
              type: 'input',
              className: 'flex-xs-100 flex-45 m-5 layout-row',
              ngModelAttrs: {
                readonly: {
                  bound: 'ng-readonly',
                  attribute: 'ng-readonly'
                }
              },
              templateOptions: {
                label: $translate.instant('WO.productRev'),
                className: 'flex forceDirLTR',
                readonly: true
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return vm.workorder.externalType === 'serviceCall'
              }
            },
            // PART_DESC
            {
              key: 'partDesc',
              type: 'input',
              className: 'flex-xs-100 flex-45 m-5 layout-row',
              ngModelAttrs: {
                readonly: {
                  bound: 'ng-readonly',
                  attribute: 'ng-readonly'
                }
              },
              templateOptions: {
                label: $translate.instant('WO.partDesc'),
                readonly: true
              }
            }
          ]
        },
        {
          className: genericRowClass,
          fieldGroup: [statusField, priorityField, assignedUsers]
        },
        {
          className: genericRowClass,
          fieldGroup: [
            // PARENT WORKORDER - LOCAL
            {
              className: 'flex-xs-100 flex-50 m-5 layout-row',
              template: `<div class="parent-workorder-section" layout="column" layout-align="center start" flex="50">
                            <span class="parent-workorder-title" translate="WO.PARENT_WORKORDER"></span>
                            <a class="parent-workorder-number" ui-sref="app.workorder.show({id: model.parentWorkorderId})">{{model.parentWorkorderNumber}}</a>
                          </div>`,
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  !$scope.isLocalWorkorder || !scope.model.parentWorkorderId
                )
              }
            },
            {
              className: 'flex-xs-100 flex-50 m-5 layout-row',
              template: `<div class="linked-workflow-section" layout="column" layout-align="center start" flex="50">
                          <span class="linked-workflow-title" translate="WO.LINKED_WORKFLOW"></span>
                          <a class="linked-workflow-number" ui-sref="app.workflow.edit({id: model.workflowId})" translate="WO.LINKED_WORKFLOW_VALUE" translate-values="{workflowName:model.workflowName, workflowVersion:model.workflowVersion}"></a>
                        </div>`,
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.workflowId || !hasCreateWorkflowPermissions
              }
            },
            {
              className: 'flex-xs-100 flex-50 m-5 layout-row',
              template: `<div class="linked-workflow-section" layout="column" layout-align="center start" flex="50">
                          <span class="linked-workflow-title" translate="WO.LINKED_WORKFLOW"></span>
                          <div class="linked-workflow-number" translate="WO.LINKED_WORKFLOW_VALUE" translate-values="{workflowName:model.workflowName, workflowVersion:model.workflowVersion}"></div>
                        </div>`,
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.workflowId || hasCreateWorkflowPermissions
              }
            },
            {
              className: 'flex-xs-100 flex-50 m-5 layout-row',
              /** @ngInject */
              controller: $scope => {
                $scope.showReleaseLogsFormly = () => {
                  showReleaseLogs()
                }
              },
              template: `<div class="linked-workflow-section" layout="column" layout-align="center start" flex="50">
              <a class="linked-workflow-number" ng-click="showReleaseLogsFormly()" translate="WF.SHOW_RELEASE_LOGS"></a>
            </div>`, // $compile()($scope)[0],
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  !scope.model.suggestedWorkflowId && !scope.model.workflowId
                )
              }
            }
          ]
        }
      ]
    }

    if (vm.workorder.source === 'priority') {
      if (vm.workorder.externalType === 'serviceCall') {
        let malfunctionCodes = []
        try {
          malfunctionCodes = await Workorder.priorityMalfunctions().$promise
          // Add null value to malfunctionCodes
        } catch (ex) {
          console.error(ex)
        } finally {
          malfunctionCodes.unshift({ id: null, name: '' })
        }
        // Add null value to malfunctionCodes
        generalFields.fieldGroup.push({
          className: genericRowClass,
          fieldGroup: [
            {
              key: 'rmaNumber',
              type: 'input',
              className: 'flex-xs-100 flex-33 m-5 layout-row',
              ngModelAttrs: {
                readonly: {
                  bound: 'ng-readonly',
                  attribute: 'ng-readonly'
                }
              },
              templateOptions: {
                label: $translate.instant('WO.RMA_NUMBER'),
                readonly: true
              }
            },
            {
              key: 'callType',
              type: 'input',
              className: 'flex-xs-100 flex-33 m-5 layout-row',
              ngModelAttrs: {
                readonly: {
                  bound: 'ng-readonly',
                  attribute: 'ng-readonly'
                }
              },
              templateOptions: {
                label: $translate.instant('WO.CALL_TYPE'),
                readonly: true
              }
            },
            {
              key: 'serviceType',
              type: 'input',
              className: 'flex-xs-100 flex-33 m-5 layout-row',
              ngModelAttrs: {
                readonly: {
                  bound: 'ng-readonly',
                  attribute: 'ng-readonly'
                }
              },
              templateOptions: {
                label: $translate.instant('WO.SERVICE_TYPE'),
                readonly: true
              }
            }
          ]
        })
        generalFields.fieldGroup.push({
          className: genericRowClass,
          fieldGroup: [
            {
              key: 'customerDiagnosis',
              type: 'input',
              className: 'flex-xs-100 flex-50 m-5 layout-row',
              ngModelAttrs: {
                readonly: {
                  bound: 'ng-readonly',
                  attribute: 'ng-readonly'
                }
              },
              templateOptions: {
                label: $translate.instant('WO.CUSTOMER_DIAGNOSIS'),
                readonly: true
              }
            },
            {
              key: 'malfunctionCode',
              type: 'selectWithSearch',
              className: 'flex-xs-100 flex-50 m-5 layout-row',
              templateOptions: {
                label: $translate.instant('WO.MALFUNCTION_CODE'),
                options: malfunctionCodes,
                onChange: () => {
                  vm.saveTrigger = true
                }
              }
            }
          ]
        })
      }
    }

    vm.infoForm = [
      {
        wrapper: 'collapsibleGroup',
        templateOptions: {
          class: 'multiple-open'
        },
        fieldGroup: [
          {
            wrapper: 'collapsibleItem',
            templateOptions: {
              class: 'fixed'
            },
            fieldGroup: [
              {
                className: genericRowClass,
                fieldGroup: [
                  // SKU
                  {
                    key: 'sku',
                    type: 'input',
                    className: 'flex-xs-100 flex-45 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.sku'),
                      className: 'flex forceDirLTR',
                      readonly: true
                    }
                  },
                  // WORKORDER NUMBER
                  {
                    key: 'workorderNumber',
                    type: 'input',
                    className: 'flex-xs-100 flex-33 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.workorderNumber'),
                      className: 'flex forceDirLTR',
                      readonly: true
                    }
                  },
                  // WORKORDER NAME
                  {
                    key: 'name',
                    type: 'input',
                    className: 'flex-xs-100 flex-33 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.workorderName'),
                      className: 'flex forceDirLTR',
                      disabled: !$scope.isLocalWorkorder,
                      readonly: !$scope.hasSaveWorkorderPermissions,
                      onChange: function (value, options) {
                        if ($scope.isLocalWorkorder) {
                          vm.saveTrigger = true
                        }
                      }
                    }
                  },
                  // QUANTITY
                  {
                    key: 'quantity',
                    type: 'input',
                    className: 'flex-xs-100 flex-33 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      type: 'number',
                      label: $translate.instant('WO.quantity'),
                      disabled: !$scope.isLocalWorkorder,
                      readonly: !$scope.hasSaveWorkorderPermissions,
                      onChange: function (value, options) {
                        if ($scope.isLocalWorkorder) {
                          vm.saveTrigger = true
                        }
                      }
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]

    const staticFields = [
      {
        wrapper: 'collapsibleGroup',
        templateOptions: {
          class: 'multiple-open'
        },
        fieldGroup: [
          // workorder - GENERAL
          generalFields,
          // workorder - ADDITIONAL
          {
            wrapper: 'collapsibleItem',
            templateOptions: {
              label: $translate.instant('WF.PROPERTIES.WORKFLOW.ADDITIONAL')
            },
            fieldGroup: [
              {
                className: genericRowClass,
                fieldGroup: [
                  // CREATED_AT
                  {
                    key: 'createdAt',
                    type: 'input',
                    formatters: [dateFormatter],
                    className: 'flex-xs-100 flex-33 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.createdAt'),
                      className: 'flex forceDirLTR',
                      readonly: true
                    }
                  },
                  // RELEASED_AT
                  {
                    key: 'releasedAt',
                    type: 'input',
                    formatters: [dateFormatter],
                    className: 'flex-xs-100 flex-33 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.releasedAt'),
                      className: 'flex-xs-100 flex forceDirLTR',
                      readonly: true
                    }
                  },
                  // EXPECTED_DATE
                  {
                    key: 'expectedDate',
                    type: 'input',
                    formatters: [dateFormatter],
                    className: 'flex-xs-100 flex-33 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.expectedDate'),
                      className: 'flex-xs-100 flex forceDirLTR',
                      readonly: true
                    },
                    hideExpression: function ($viewValue, $modelValue, scope) {
                      return (
                        $scope.isLocalWorkorder || $scope.isManagerWorkorder
                      )
                    }
                  },
                  // EXPECTED_DATE
                  {
                    key: 'expectedDate',
                    type: 'dateTimePicker',
                    className:
                      'flex-xs-100 flex-33 m-5 layout-row date-mobile-input',
                    templateOptions: {
                      label: $translate.instant('WO.expectedDate'),
                      className: 'flex forceDirLTR',
                      onChange: function (value, options) {
                        vm.saveTrigger =
                          (_.isNil(value) && vm.initialExpectedDate) ||
                          (!_.isNil(value) &&
                            value.setSeconds(0, 0) !== vm.initialExpectedDate)
                        if (
                          $scope.isManagerWorkorder &&
                          PermissionUtils.isPermit(
                            'Workorder',
                            'saveLocalWorkorder'
                          )
                        ) {
                          $scope.saveDebouncedFn(value)
                        }
                      }
                    },
                    hideExpression: function ($viewValue, $modelValue, scope) {
                      return (
                        !$scope.isLocalWorkorder && !$scope.isManagerWorkorder
                      )
                    }
                  }
                ]
              },
              {
                className: genericRowClass,
                fieldGroup: [
                  // START
                  {
                    key: 'start',
                    type: 'input',
                    formatters: [dateTimeFormatter],
                    className: 'flex-xs-100 flex-50 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.start'),
                      className: 'flex-xs-100 flex forceDirLTR',
                      readonly: true
                    }
                  },
                  // END
                  {
                    key: 'end',
                    type: 'input',
                    formatters: [dateTimeFormatter],
                    className: 'flex-xs-100 flex-50 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.end'),
                      className: 'flex-xs-100 flex forceDirLTR',
                      readonly: true
                    }
                  }
                ]
              },
              {
                className: genericRowClass,
                fieldGroup: [
                  // CYCLE_TIME
                  {
                    key: 'cycleTime',
                    type: 'textarea',
                    formatters: [durationFormatter],
                    className:
                      'flex-xs-100 flex-50 m-5 layout-row mobile-bigger-textarea',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.CYCLE_TIME'),
                      className: 'flex',
                      readonly: true
                    }
                  },
                  // NET_TIME
                  {
                    key: 'netTime',
                    type: 'textarea',
                    formatters: [durationFormatter],
                    className:
                      'flex-xs-100 flex-50 m-5 layout-row mobile-bigger-textarea',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.NET_TIME'),
                      className: 'flex',
                      rows: 1,
                      readonly: true
                    }
                  }
                ]
              },
              {
                className: genericRowClass,
                fieldGroup: [
                  {
                    key: 'erpProductionStartDate',
                    type: 'input',
                    formatters: [dateTimeFormatter],
                    className: 'flex-xs-100 flex-50 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.ERP_PRODUCTION_START_DATE'),
                      className: 'flex forceDirLTR',
                      rows: 1,
                      readonly: true
                    }
                  },
                  {
                    key: 'erpProductionEndDate',
                    type: 'input',
                    formatters: [dateTimeFormatter],
                    className: 'flex-xs-100 flex-50 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.ERP_PRODUCTION_END_DATE'),
                      className: 'flex forceDirLTR',
                      readonly: true
                    }
                  }
                ],
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return $scope.isLocalWorkorder || $scope.isManagerWorkorder
                }
              },
              {
                className: genericRowClass,
                fieldGroup: [
                  // ERP STATUS
                  {
                    key: 'erpStatus',
                    type: 'input',
                    className: 'flex-xs-100 flex-50 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.status'),
                      className: 'flex',
                      readonly: true
                    }
                  },
                  // PARENT_WORKORDER
                  {
                    key: 'parentWorkorderNumber',
                    type: 'input',
                    className: 'flex-xs-100 flex-50 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.parentWorkorderNumber'),
                      className: 'flex forceDirLTR',
                      readonly: true
                    }
                  }
                ],
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return $scope.isLocalWorkorder || $scope.isManagerWorkorder
                }
              },
              {
                className: genericRowClass,
                fieldGroup: [
                  // ORDER_NUMBER
                  {
                    key: 'orderNumber',
                    type: 'input',
                    className: 'flex-xs-100 flex-45 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.orderNumber'),
                      readonly: true
                    }
                  },
                  // ORDER_LINE
                  {
                    key: 'orderLine',
                    type: 'input',
                    className: 'flex-xs-100 flex-10 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.orderLine'),
                      className: 'flex forceDirLTR',
                      readonly: true
                    }
                  },
                  // PROJECT_DESCRIPTION
                  {
                    key: 'project.description',
                    type: 'input',
                    className: 'flex-xs-100 flex-45 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.project.description'),
                      readonly: true
                    }
                  }
                ],
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return $scope.isLocalWorkorder || $scope.isManagerWorkorder
                }
              },
              {
                className: genericRowClass,
                fieldGroup: [
                  {
                    key: 'customer.accountName',
                    type: 'input',
                    className: 'flex-xs-100 flex-45 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.CUSTOMER.ACCOUNT_NAME'),
                      readonly: true
                    }
                  },
                  {
                    key: 'isSerial',
                    type: 'checkbox',
                    className: 'flex-xs-100 flex-10 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.isSerial'),
                      className: 'flex',
                      disabled: true
                    }
                  },
                  {
                    key: 'groups',
                    type: 'modelSelect',
                    className: 'flex-xs-100 flex-33 m-5 layout-row ',
                    templateOptions: {
                      label: $translate.instant('USER.GROUPS'),
                      multiple: true,
                      disabled: !PermissionUtils.isPermit(
                        'Workorder',
                        'changeGroups'
                      ),
                      findMethod: Group.find,
                      mapObject: { id: 'id', name: 'name' },
                      baseFilterObject: {
                        where: {},
                        fields: {
                          name: true,
                          id: true
                        }
                      },
                      onChange: function (value, options) {
                        if (!workorderGroups) {
                          workorderGroups = options.initialValue
                        }
                        if (!_.isEqual(value, workorderGroups)) {
                          // eslint-disable-next-line
                          Workorder.changeGroups({
                            workorderId: vm.workorder.id,
                            groups: value
                          }).$promise
                          workorderGroups = value
                        }
                      }
                    }
                  }
                ]
              }
            ],
            hideExpression: function ($viewValue, $modelValue, scope) {
              return false
            }
          },
          // MANAGER workorder - CUSTOMER
          {
            wrapper: 'collapsibleItem',
            templateOptions: {
              label: $translate.instant('WO.CUSTOMER.DETAILS')
            },
            fieldGroup: [
              {
                className: genericRowClass,
                fieldGroup: [
                  // ACCOUNT_NAME
                  {
                    key: 'customer.accountName',
                    type: 'input',
                    className: 'flex-xs-100 flex-33 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.CUSTOMER.ACCOUNT_NAME'),
                      readonly: true
                    }
                  },
                  // PHONE 1
                  {
                    key: 'customer.phone1',
                    type: 'input',
                    className: 'flex-xs-100 flex-33 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.CUSTOMER.PHONE'),
                      className: 'flex forceDirLTR',
                      readonly: true
                    }
                  },
                  // PHONE 2
                  {
                    key: 'customer.phone2',
                    type: 'input',
                    className: 'flex-xs-100 flex-33 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: `${$translate.instant('WO.CUSTOMER.PHONE')} 2`,
                      className: 'flex forceDirLTR',
                      readonly: true
                    },
                    hideExpression: function ($viewValue, $modelValue, scope) {
                      return (
                        !scope.model.customer ||
                        !scope.model.customer.phone2 ||
                        scope.model.customer.phone2 === ''
                      )
                    }
                  }
                ]
              },
              {
                className: genericRowClass,
                fieldGroup: [
                  {
                    key: 'customer.city',
                    type: 'input',
                    className: 'flex-xs-100 flex-50 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.CUSTOMER.CITY'),
                      className: 'flex forceDirLTR',
                      readonly: true
                    }
                  },
                  {
                    key: 'customer.address',
                    type: 'input',
                    className: 'flex-xs-100 flex-50 m-5 layout-row',
                    ngModelAttrs: {
                      readonly: {
                        bound: 'ng-readonly',
                        attribute: 'ng-readonly'
                      }
                    },
                    templateOptions: {
                      label: $translate.instant('WO.CUSTOMER.ADDRESS'),
                      className: 'flex forceDirLTR',
                      readonly: true
                    }
                  }
                ]
              }
            ],
            hideExpression: function ($viewValue, $modelValue, scope) {
              return !$scope.isManagerWorkorder
            }
          },
          // workorder - custom fields
          {
            wrapper: 'collapsibleItem',
            templateOptions: {
              label: $translate.instant('WO.CUSTOM_FIELDS')
            },
            fieldGroup: [],
            hideExpression: function ($viewValue, $modelValue, scope) {
              return scope.fields[3].fieldGroup.length === 0
            }
          }
        ]
      }
    ]

    staticFields[0].fieldGroup.push({
      wrapper: 'collapsibleItem',
      templateOptions: {
        label: $translate.instant('WO.WORKORDER_DESCRIPTION')
      },
      fieldGroup: [
        {
          key: 'description',
          type: 'inputTextAngular',
          templateOptions: {
            style: 'width:100%',
            disabled: true,
            taToolbar:
              "[['fontColor','bold','ul','ol','linkToDocument','indent','outdent','insertLink','clear','justifyLeft', 'justifyCenter', 'justifyRight']]",
            label: $translate.instant('WO.WORKORDER_DESCRIPTION'),
            onChange: function () {
              vm.saveTrigger = true
            }
          }
        }
      ],
      hideExpression: function ($viewValue, $modelValue, scope) {
        return (
          vm.workorder.source !== 'priority' ||
          vm.workorder.externalType === 'serviceCall'
        )
      }
    })

    staticFields[0].fieldGroup.push({
      wrapper: 'collapsibleItem',
      templateOptions: {
        label: $translate.instant('WO.FAULT_DESCRIPTION')
      },

      fieldGroup: [
        {
          key: 'faultDescription',
          type: 'inputTextAngular',
          templateOptions: {
            style: 'width:100%',
            taToolbar:
              "[['fontColor','bold','ul','ol','linkToDocument','indent','outdent','insertLink','clear','justifyLeft', 'justifyCenter', 'justifyRight']]",
            label: $translate.instant('WO.FAULT_DESCRIPTION'),
            onChange: function () {
              vm.saveTrigger = true
            }
          }
        }
      ],
      hideExpression: function ($viewValue, $modelValue, scope) {
        return (
          vm.workorder.source !== 'priority' ||
          vm.workorder.externalType !== 'serviceCall'
        )
      }
    })
    staticFields[0].fieldGroup.push({
      wrapper: 'collapsibleItem',
      templateOptions: {
        label: $translate.instant('WO.FIX_DESCRIPTION')
      },

      fieldGroup: [
        {
          key: 'fixDescription',
          type: 'inputTextAngular',
          templateOptions: {
            style: 'width:100%',
            taToolbar:
              "[['fontColor','bold','ul','ol','linkToDocument','indent','outdent','insertLink','clear','justifyLeft', 'justifyCenter', 'justifyRight']]",
            label: $translate.instant('WO.FIX_DESCRIPTION'),
            onChange: function () {
              vm.saveTrigger = true
            }
          }
        }
      ],
      hideExpression: function ($viewValue, $modelValue, scope) {
        return (
          vm.workorder.source !== 'priority' ||
          vm.workorder.externalType !== 'serviceCall'
        )
      }
    })
    const generatedFields = FormlyHelper.buildFields(
      staticFields,
      Workorder,
      '0.fieldGroup.0.fieldGroup'
    )
    vm.form = fixCustomFields(generatedFields)
    vm.load()
  }

  initScreen()

  $scope.changeUnitPriority = async function changeUnitPriority (
    stockId,
    priority
  ) {
    try {
      await Stock.updatePriority({ stockId, priority }).$promise
      updateRowInKendoByParameters(stockId, ['priority'], 'devicesKendoGrid')
    } catch (err) {
      console.error(err)
      $rootScope.showErrorToast('WO.ERRORS.PRIORITY_CHANGE_FAILED', true)
    }
  }

  const updateRowInKendoByParameters = function updateRowInKendoByParameters (
    id,
    parameters,
    kendoPath = 'validUnitsKendoGrid'
  ) {
    const dataItem = vm[kendoPath].instance.dataSource.get(id)
    if (vm.unitsById[id]) {
      parameters.forEach(parameter => {
        dataItem.set(parameter, vm.unitsById[id][parameter])
      })
    }
  }

  $scope.$on('$destroy', () => {
    debug('destroy scope')
    nextplusSocket.angularSocket.removeListener('changes')
    vm.autoRefreshWorkorder = false
  })

  const initManagerWorkorder = async function initManagerWorkorder () {
    const managerOrderLinesColumns = [
      // WORKORDER_NUMBER
      {
        uniqueId: 'c740c848-7420-47f7-8568-beaa2b799959',
        field: 'lineNo',
        translateCode: 'WO.LINE',
        width: '5%'
      },
      // itemId
      {
        uniqueId: '5589d414-f82d-41cf-96b5-46a0f3ca7789',
        field: 'itemId',
        translateCode: 'WO.sku',
        width: 'auto'
      },
      // itemName
      {
        uniqueId: 'c6f198e5-dbc5-4227-9d6a-0f97a09832a2',
        field: 'itemName',
        translateCode: 'WO.partDesc',
        width: 'auto'
      },
      // QUANTITY
      {
        uniqueId: '8f0ffbc4-6147-4f9a-8142-a2e0aa73580a',
        field: 'quantity',
        translateCode: 'WO.quantity',
        type: 'number',
        width: 'auto'
      }
    ]

    const managerOrderLinesTableSetup = {
      // stateName
      ignoreParams: true,
      serverSide: false,
      data: vm.workorder.orderLines,
      baseFilter: {},
      pageSize: 1000,
      autoSize: false,
      selectedViewId: 'systemDefault',
      columns: managerOrderLinesColumns
    }
    vm.orderLines = await KendoGridHelper.GridInstance(
      managerOrderLinesTableSetup,
      $scope,
      managerOrderLinesTableSetup.columns
    )
  }

  const successToast = function successToast (translateCode) {
    $mdToast.show(
      $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'body',
        theme: 'success-toast',
        hideDelay: 6000
      })
    )
    $mdToast.updateTextContent($translate.instant(translateCode))
  }
  $scope.editKitItem = async function editKitItem (kitItemId) {
    $mdDialog
      .show({
        /** @ngInject */
        locals: {
          kitItem: vm.kitItemsById[kitItemId],
          kitItems: []
        },
        multiple: true,
        controller: KitModificationDialogController,
        template: require('../kit-modification/kit-modification-dialog.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        clickOutsideToClose: false,
        escapeToClose: false,
        resolve: {
          ResolvedPart: PartAssembly =>
            PartAssembly.findOne({
              filter: {
                where: {
                  number: vm.kitItemsById[kitItemId].sku
                },
                fields: {
                  id: true,
                  name: true,
                  number: true,
                  managedByRevision: true,
                  revisions: true,
                  currentRevision: true
                }
              }
            }).$promise
        }
      })
      .then(async model => {
        $rootScope.loadingProgress = true
        if (model.isDelete) {
          Workorder.removeKitItem({
            workorderId: vm.workorder.id,
            kitItemId: model.id
          })
            .$promise.then(() => {
              vm.kitItemsTable.instance.dataSource.remove(
                vm.kitItemsTable.instance.dataSource._data.find(
                  kitItem => kitItem.id === model.id
                )
              )
              const kitItemIndex = kitItems.findIndex(
                kitItem => kitItem.id === kitItemId
              )
              kitItems.splice(kitItemIndex, 1)
              $rootScope.loadingProgress = false
              successToast('WO.KIT_MODIFICATION.ITEM_DELETED_SUCCESSFULLY')
            })
            .catch(error => {
              $rootScope.loadingProgress = false
              if (error.data.error.details) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.message,
                      error.data.error.details
                    )
                )
              } else if (error.data.error.message) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.message
                    )
                )
              } else {
                $rootScope.showErrorToast(': ' + error.message)
              }
            })
        } else {
          const obj = {
            workorderId: vm.workorder.id,
            kitItemId: model.id,
            quantity: model.quantity
          }
          if (model.rev) {
            obj.rev = model.rev
          }
          Workorder.editKitItem(obj)
            .$promise.then(() => {
              const dataItem = vm.kitItemsTable.instance.dataSource.get(
                model.id
              )
              if (vm.kitItemsById[model.id]) {
                dataItem.set('quantity', model.quantity)
                dataItem.set('rev', model.rev)
              }
              const kitItemIndex = kitItems.findIndex(
                kitItem => kitItem.id === kitItemId
              )
              kitItems[kitItemIndex].quantity = model.quantity
              kitItems[kitItemIndex].rev = model.rev
              $rootScope.loadingProgress = false
              successToast('WO.KIT_MODIFICATION.ITEM_EDITED_SUCCESSFULLY')
            })
            .catch(error => {
              $rootScope.loadingProgress = false
              if (error.data.error.details) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.message,
                      error.data.error.details
                    )
                )
              } else if (error.data.error.message) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.message
                    )
                )
              } else {
                $rootScope.showErrorToast(': ' + error.message)
              }
            })
        }
      })
  }
  $scope.addKitItem = function addKitItem () {
    $mdDialog
      .show({
        /** @ngInject */
        multiple: true,
        controller: KitModificationDialogController,
        template: require('../kit-modification/kit-modification-dialog.template.html'),
        locals: angular.copy({
          kitItem: null,
          kitItems
        }),
        parent: angular.element(document.body),
        targetEvent: '',
        clickOutsideToClose: false,
        escapeToClose: false,
        resolve: {
          ResolvedPart: () => null
        }
      })
      .then(async model => {
        $rootScope.loadingProgress = true
        if (model) {
          const obj = {
            workorderId: vm.workorder.id,
            sku: model.sku,
            quantity: model.quantity
          }
          if (model.rev) {
            obj.rev = model.rev
          }
          Workorder.addKitItem(obj)
            .$promise.then(kitItem => {
              const newKitItem = _.cloneDeep(kitItem.toJSON())
              kitItems.push(newKitItem)
              vm.kitItemsById[kitItem.id] = newKitItem
              vm.kitItemsTable.instance.dataSource.add(newKitItem)
              $rootScope.loadingProgress = false
              successToast('WO.KIT_MODIFICATION.ITEM_ADDED_SUCCESSFULLY')
            })
            .catch(error => {
              $rootScope.loadingProgress = false
              console.log(error)
              if (
                [
                  'QUANTITY_SHOULD_DIVIDE_BY_WORKORDER_QUANTITY',
                  'KIT_ITEM_ADD_NO_EACH',
                  'PHANTOM_PART_ERROR',
                  'WORKORDER_NOT_CREATE_STOCK'
                ].includes(error?.data?.error?.code)
              ) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.code,
                      error?.data?.error?.details
                        ? error.data.error.details
                        : {}
                    )
                )
              } else {
                $rootScope.showErrorToast(': ' + error.message)
              }
            })
        }
      })
  }
  $scope.showPart = function showPart (partNumber, partRev = null) {
    PartViewHelper.openPartViewDialog(partNumber, partRev, {})
  }
  const initKitItemsTable = async function initKitItemsTable () {
    let kitItemsData = kitItems.map(item => {
      item.quantity = item.quantity.toFixed(3)
      item.issuedQuantity = item.issuedQuantity.toFixed(3)
      if (!item.issuedToKit) {
        item.missing = false
      } else {
        item.missing = item.quantity !== item.issuedQuantity
      }
      return item
    })
    kitItemsData = _.orderBy(
      kitItemsData,
      ['issuedToKit', 'manualIssue', 'missing'],
      ['desc', 'desc', 'desc']
    )

    const tableColumns = [
      // ICON
      {
        uniqueId: '3d3ed3b4-a692-418b-bea9-1d8dfc7450b5',
        field: 'none',
        title: '#',
        width: 'auto',
        sortable: false,
        filterable: false,
        trustedTemplate: data => {
          if (!data.issuedToKit && !data.manualIssue) {
            return '<md-icon md-font-icon="icon-checkbox-blank-circle" class="icon orange-500-fg"></md-icon>'
          } else if (!data.issuedToKit && data.manualIssue) {
            return '<md-icon md-font-icon="icon-checkbox-blank-circle" class="icon blue-500-fg"></md-icon>'
          } else if (data.issuedToKit && data.missing) {
            return '<md-icon md-font-icon="icon-checkbox-blank-circle" class="icon red-500-fg"></md-icon>'
          } else if (data.issuedToKit && !data.missing) {
            return '<md-icon md-font-icon="icon-checkbox-blank-circle" class="icon green-500-fg"></md-icon>'
          }
          return ''
        }
      },
      // PART_NUMBER
      {
        uniqueId: '1c3ed3b4-a692-418b-bea9-9d8dfc0140b5',
        field: 'sku',
        translateCode: 'WF.PART_NUMBER',
        width: 'auto',
        trustedTemplate: data => {
          return `<a style="cursor: pointer;" ng-click="showPart('${htmlWork.htmlEncode(
            data.sku
          )}', '${htmlWork.htmlEncode(data.rev)}')">${data.sku}</a>`
        }
        // media: '(min-width: 768px)',
      },
      // PART_REVISION
      {
        uniqueId: 'ff2c8a69-3214-4808-9eb8-75b6aa9161c9',
        field: 'rev',
        translateCode: 'WF.PART_REVISION',
        width: 'auto'
        // media: '(min-width: 768px)',
      },
      // PART_NUMBER
      {
        uniqueId: '7d3ed3b4-3b92-418b-bea9-8c8dfc0140b2',
        field: 'partDesc',
        translateCode: 'WF.PART_DESCRIPTION',
        width: 'auto'
        // media: '(min-width: 768px)',
      },
      // UNIT
      {
        uniqueId: '6f3ed3b4-a692-418b-bea9-9d8dfc014012',
        field: 'unit',
        translateCode: 'WF.UNIT',
        width: 'auto'
        // media: '(min-width: 768px)',
      },
      // NEEDED_AMOUNT
      {
        uniqueId: '1384298b-29d7-430f-9a58-5ee5f55913af',
        field: 'quantity',
        translateCode: 'WF.NEEDED_AMOUNT',
        type: 'number',
        width: 'auto'
        // media: '(min-width: 768px)',
      },
      // ISSUED_AMOUNT
      {
        uniqueId: '1d773c79-060c-2101-8ba2-1bccb51888ab',
        field: 'none2',
        translateCode: 'WF.ISSUED_AMOUNT',
        width: 'auto',
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          if (data.issuedToKit) {
            return `<span>${htmlWork.htmlEncode(data.issuedQuantity)}</span>`
          } else if (!data.issuedToKit && !data.manualIssue) {
            return '<span>Free Stock</span>'
          } else if (!data.issuedToKit && data.manualIssue) {
            return `<span>${$translate.instant('WF.MANUAL_ISSUE')}</span>`
          }
          return ''
        }
      }
    ]

    if ($scope.isLocalWorkorder) {
      tableColumns.shift()
      tableColumns.pop()
    }
    if ($scope.hasSaveWorkorderPermissions) {
      tableColumns.push(
        // ACTIONS
        {
          uniqueId: '191dc939-725e-4dc9-95cf-e0dfbef85db6',
          field: 'id',
          translateCode: 'WO.actions',
          width: '20%',
          filterable: false,
          sortable: false,
          trustedTemplate: data => {
            return `<div layout="row">
                  <md-button class="md-icon-button" ng-if="${
                    data.source === 'local'
                  } " ng-click="editKitItem('${
              data.id
            }')" data-testid="preset-${data.id}">
                    <md-icon md-font-icon="icon-pencil" class="s18"></md-icon>
                  </md-button>
                </div>`
          }
        }
      )
    }
    const defaultTableSetup = {
      // stateName
      ignoreParams: true,
      serverSide: false,
      data: kitItemsData,
      cleanBaseFilter: {},
      autoSize: false,
      baseFilter: {},
      selectedViewId: 'systemDefault',
      columns: tableColumns
    }

    vm.kitItemsTable = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      tableColumns
    )
  }

  const initChangeLogTable = async function initChangeLogTable () {
    const tableColumns = [
      // TYPE
      {
        uniqueId: '7a775fdc-da0a-49ed-84ec-4e98d07ca383',
        field: 'type',
        translateCode: 'WO.CHANGE_LOG.CHANGE',
        filterable: false,
        sortable: false,
        width: 'auto',
        template: data => {
          return $translate.instant(`WO.CHANGE_LOG.${data.type}`)
        }
        // media: '(min-width: 768px)',
      },
      // TYPE
      {
        uniqueId: '84e6f6bc-1952-48ee-a620-c6ab86004a70',
        field: 'userId',
        translateCode: 'WO.CHANGE_LOG.USER',
        filterable: false,
        sortable: false,
        width: 'auto',
        trustedTemplate: data => {
          return `<users-avatar max-users="1" get-user-details="true" users="['${data.userId}']"></users-avatar>`
        }
        // media: '(min-width: 768px)',
      },
      // OLD_VALUE
      {
        uniqueId: '9d5bdb70-5a4e-4d43-b28d-29711494ac90',
        field: 'oldValue',
        type: 'object',
        translateCode: 'WO.CHANGE_LOG.OLD_VALUE',
        filterable: false,
        sortable: false,
        width: 'auto',
        trustedTemplate: data => {
          switch (data.type) {
            case 'SKU_CONVERSION':
              return `<div layout="column" layout-align="center start">
                        <div layout="row" layout-align="start center">
                          <span translate="WO.SKU_CONVERSION.SKU"></span>:&nbsp;
                          <span><strong>${htmlWork.htmlEncode(
                            data.oldValue.sku
                          )}</strong></span>
                        </div>
                        <div layout="row" layout-align="start center">
                          <span translate="WO.SKU_CONVERSION.SERIAL"></span>:&nbsp;
                          <span><strong>${htmlWork.htmlEncode(
                            data.oldValue.serial
                          )}</strong></span>
                        </div>
                      </div>`
            case 'WORKORDER_STATUS_CHANGE':
              return `<div layout="column" layout-align="center start">
                        <div layout="row" layout-align="start center">
                          <span translate="WO.STATUS"></span>:&nbsp;
                          <span><strong>${
                            htmlWork.htmlEncode(data.oldStatusName) || ''
                          }</strong></span>
                        </div>
                      </div>`
            case 'WORKORDER_RESET':
              return '--'
            default:
              return data.oldValue
          }
        }
      },
      // NEW_VALUE
      {
        uniqueId: 'd0c3a905-f931-4adb-98bb-fac24e8807c7',
        field: 'newValue',
        type: 'object',
        translateCode: 'WO.CHANGE_LOG.NEW_VALUE',
        filterable: false,
        sortable: false,
        width: 'auto',
        trustedTemplate: data => {
          switch (data.type) {
            case 'SKU_CONVERSION':
              return `<div layout="column" layout-align="center start">
                        <div layout="row" layout-align="start center">
                          <span translate="WO.SKU_CONVERSION.SKU"></span>:&nbsp;
                          <span><strong>${htmlWork.htmlEncode(
                            data.newValue.sku
                          )}</strong></span>
                        </div>
                        <div layout="row" layout-align="start center">
                          <span translate="WO.SKU_CONVERSION.SERIAL"></span>:&nbsp;
                          <span><strong>${htmlWork.htmlEncode(
                            data.newValue.serial
                          )}</strong></span>
                        </div>
                      </div>`
            case 'WORKORDER_STATUS_CHANGE':
              return `<div layout="column" layout-align="center start">
                      <div layout="row" layout-align="start center">
                        <span translate="WO.STATUS"></span>:&nbsp;
                        <span><strong>${
                          htmlWork.htmlEncode(data.newStatusName) || ''
                        }</strong></span>
                      </div>
                    </div>`
            case 'WORKORDER_RESET':
              return '--'
            default:
              return htmlWork.htmlEncode(data.newValue)
          }
        }
      },
      // fields
      {
        uniqueId: 'a00444ef-3b91-4c33-b72a-eb6722b242d4',
        field: 'fields',
        // type: 'object',
        translateCode: 'Fields',
        filterable: false,
        sortable: false,
        width: '800px',
        trustedTemplate: data => {
          switch (data.type) {
            case 'SKU_CONVERSION':
              return ''
            case 'WORKORDER_STATUS_CHANGE':
              if (data.fields) {
                const fieldObject = {
                  fieldIds: data.fields.map(field => field.fieldId)
                }
                const valueObject = {}
                data.fields.forEach(field => {
                  valueObject[field.fieldId] = field.value
                })
                return FieldUtilsService.generateSubFormValueHTML(
                  $scope.changeLogUniqueFields,
                  [valueObject],
                  fieldObject,
                  'column',
                  true,
                  false
                )
              } else {
                return ''
              }

            default:
              return ''
          }
        }
      },
      // Date
      {
        uniqueId: '3273354e-6849-40f8-83e7-19abb1a786f8',
        field: 'date',
        type: 'date',
        translateCode: 'WO.CHANGE_LOG.DATE',
        filterable: false,
        sortable: false,
        width: 'auto',
        template: data => {
          return data.date === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.date, 'dateTime')
        }
        // media: '(min-width: 768px)',
      }
    ]

    const baseFilter = {
      where: { deletedAt: null },
      order: 'date DESC'
    }
    const defaultTableSetup = {
      // stateName
      ignoreParams: true,
      serverSide: false,
      data: vm.workorder.changeLog,
      cleanBaseFilter: {},
      baseFilter,
      autoSize: false,
      selectedViewId: 'systemDefault',
      columns: tableColumns
    }

    vm.changeLog = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      tableColumns
    )
  }

  const openFieldsDialog = async function openFieldsDialog (fields, statusId) {
    const dbFields = []
    const fieldIds = fields.map(field => field.fieldId)
    const tempFields = await WorkorderStatus.getFieldsByFieldIds({
      fieldIds
    }).$promise
    const fieldsByFieldId = _.keyBy(tempFields, 'id')
    fieldIds.forEach(fieldId => {
      if (fieldsByFieldId[fieldId]) {
        dbFields.push(fieldsByFieldId[fieldId])
      }
    })
    return $mdDialog
      .show({
        controller: WorkorderStatusFieldsController,
        template: require('../statuses/workorder-status-fields-dialog.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        multiple: true,
        locals: {
          dbFields,
          fields,
          workorderNumber: vm.workorder.workorderNumber
        },
        clickOutsideToClose: false
      })
      .then(
        async function (response) {
          if (response) {
            await WorkorderStatus.changeStatus({
              workorderId: vm.workorder.id,
              statusId,
              fields: response.fields
            }).$promise
            return true
          } else {
            return false
          }
        },
        function () {
          return false
        }
      )
  }

  vm.load = function load () {
    $scope.bulkActionOptions = [
      { name: $translate.instant('WO.start'), value: 'start' }
    ]
    if ($rootScope.appSettings.priorityLabels.Session > 0) {
      $scope.bulkActionOptions.push({
        name: $translate.instant('COMMON.PRINT'),
        value: 'print'
      })
    }
    vm.validBulkAction = 'start'
    vm.invalidBulkAction = 'start'
    initChangeLogTable()
    if ($scope.isLocalWorkorder) {
      initKitItemsTable()
    } else if ($scope.isManagerWorkorder) {
      initManagerWorkorder()
    } else {
      initKitItemsTable()
    }
    vm.NEW_WORKFLOW_VERSION = $translate.instant('WO.NEW_WORKFLOW_VERSION', {})
    vm.workorder.priority = WorkorderWithStock.priority || 0
    stocks = _.orderBy(stocks, 'indicator')
    const locksBySessionId = _.keyBy(vm.workorder.locks, 'modelId')
    stocks.map(stock => {
      if (_.isUndefined(stock.priority)) {
        stock.priority = 0
      }
      stock.currentAction = _.find(stock.actions, { completedQty: 0 })

      if (!$rootScope.appSettings.allowMultiUsersInSession) {
        stock.workflowSessionItem.lock =
          locksBySessionId[stock.workflowSessionItem.id]
      } else {
        delete stock.workflowSessionItem.lock
      }
      return stock
    })
    setWorkorderStatus()
    if (vm.autoRefreshWorkorder) $timeout(vm.refreshWorkorder, 1000)
  }

  const setWorkorderStatus = function setWorkorderStatus () {
    if ($scope.isManagerWorkorder) {
      if (
        vm.workorder.workflowId ||
        vm.workorder.workflowRecordId ||
        vm.workorder.suggestedWorkflowId
      ) {
        vm.revMatch = vm.workflowFound = true
      }
    } else {
      const { enforceOldRevisionValidation, enforceRevisionValidation } =
        $rootScope.appSettings
      if (
        vm.workorder.workflowId ||
        vm.workorder.workflowRecordId ||
        (vm.workorder.start !== null && vm.workorder.start !== '--')
      ) {
        vm.workflowFound = vm.revMatch = true
      }
      if (
        enforceOldRevisionValidation === true &&
        vm.workorder.runningOnOldVersion === true &&
        (_.isNil(vm.workorder.newVersionApprovedUserId) ||
          vm.workorder.newVersionApprovedUserId === '')
      ) {
        vm.hasNewVersion = false
      }
      if (
        !enforceRevisionValidation ||
        (vm.workorder.workflowPartRevs &&
          vm.workorder.workflowPartRevs.length > 0 &&
          vm.workorder.workflowPartRevs.includes(vm.workorder.productRev)) ||
        vm.workorder.productRev === null ||
        vm.workorder.unmatchRevApprovedUserId
      ) {
        if (vm.workorder.externalType === 'serviceCall') {
          vm.revMatch = true
        } else {
          vm.revMatch = vm.workflowFound = true
          return
        }
      }
      if (vm.workorder.suggestedWorkflowId) {
        vm.workflowFound = true
      }
    }
    vm.workflowFound = vm.workflowFound || false
  }

  vm.showKit = function showKit (stockId, ev) {
    $mdDialog.show({
      controller: WorkflowKitController,
      controllerAs: 'vm',
      templateUrl:
        './modules/main/workflow/show/dialogs/workflow.kit.template.html',
      parent: angular.element(document.body),
      targetEvent: ev,
      locals: {
        preview: false,
        stocksOrWorkorder: stockId,
        model: 'stock'
      },
      multiple: true,
      escapeToClose: false,
      clickOutsideToClose: true,
      fullscreen: true
    })
  }

  vm.showSign = function showSign (property = 'unmatchRevApprovedUserId') {
    $mdDialog
      .show({
        controller: require('../../workflow/show/dialogs/workflow.sign.controller'),
        controllerAs: 'vm',
        templateUrl:
          './modules/main/workflow/show/dialogs/workflow.sign.template.html',
        parent: angular.element(document.body),
        locals: {
          preview: false
        },
        clickOutsideToClose: true,
        escapeToClose: false,
        multiple: true,
        fullscreen: true
      })
      .then(
        function (user) {
          Workorder.prototype$patchAttributes(
            { id: vm.workorder.id },
            { [property]: user.id }
          ).$promise.then(function (res) {
            vm.workorder[property] = user.id
            setWorkorderStatus()
          })
        },
        function () {}
      )
  }

  vm.openConfiguration = async function (sessionId) {
    await openConfiguration($mdDialog, sessionId)
  }

  vm.refreshWorkorder = async function refreshWorkorder () {
    if (!vm.autoRefreshWorkorder) return false
    counter++
    const workorder = await Workorder.get({
      id: $stateParams.id,
      fetchRemoteWorkflow: false,
      fetchERPWorkorder: false
    }).$promise
    vm.workorder.productRev = workorder.productRev
    vm.workorder.unmatchRevApprovedUserId = workorder.unmatchRevApprovedUserId
    vm.formById = _.keyBy(workorder.forms, 'id')
    sessions = workorder.sessions
    stocks = workorder.stocks
    setWorkorderStatus()
    if (Array.isArray(sessions) && sessions.length) {
      sessions.map(session => {
        session.formDataGrouped = handleForms(session.formData)
        if ($rootScope?.appSettings?.allowMultiUsersInSession) {
          session.lock = null
        }
        return session
      })
    }
    createUnits()
    createDevices()
    if (counter < 10) {
      if (counter < 5) {
        $timeout(vm.refreshWorkorder, 3000)
      } else {
        $timeout(vm.refreshWorkorder, 8000)
      }
    }
  }

  vm.checkAllUnitsSelectedByType = type => {
    if (type === 'valid') {
      vm.allValidUnitsSelected = units
        .filter(unit => unit.validQuantity > 0)
        .every(unit => vm.selectedValidUnit[unit.id])
    } else {
      vm.allInvalidUnitsSelected = units
        .filter(unit => unit.validQuantity === 0)
        .every(unit => vm.selectedInvalidUnit[unit.id])
    }
  }
  vm.checkAllUnitsByType = type => {
    if (type === 'valid') {
      _.each(units, unit => {
        if ($rootScope.appSettings.allowMultiUsersInSession || !unit.lock) {
          if (vm.allValidUnitsSelected) {
            vm.selectedValidUnit[unit.id] = vm.allValidUnitsSelected
          } else {
            vm.selectedValidUnit = {}
          }
        }
      })
    } else {
      _.each(units, unit => {
        if ($rootScope.appSettings.allowMultiUsersInSession || !unit.lock) {
          if (vm.allInvalidUnitsSelected) {
            vm.selectedInvalidUnit[unit.id] = vm.allInvalidUnitsSelected
          } else {
            vm.selectedInvalidUnit = {}
          }
        }
      })
    }
  }
  vm.checkAllDevicesSelected = () => {
    vm.allDevicesSelected = stocks.every(stock => vm.selectedDevice[stock.id])
  }
  vm.checkSomeDevicesSelected = () => {
    return stocks.some(stock => vm.selectedDevice[stock.id])
  }
  vm.checkAllDevices = () => {
    _.each(stocks, stock => {
      if (vm.allDevicesSelected) {
        vm.selectedDevice[stock.id] = vm.allDevicesSelected
      } else {
        vm.selectedDevice = {}
      }
    })
  }
  const chooseSourceSession = async function chooseSourceSession (sessionIds) {
    return $mdDialog.show({
      controller: function ($scope, locals) {
        $scope.options = locals.options
        $scope.header = $translate.instant('WO.SELECT_SOURCE_SESSION')
        $scope.cancel = function cancel () {
          $mdDialog.cancel()
        }
        $scope.save = function save () {
          $mdDialog.hide($scope.selectedSession.value)
        }
        $scope.selectedSession = null
      },
      template: `<md-dialog style="height: 300px; width: 400px;">
                          <md-toolbar>
                            <div class="md-toolbar-tools">
                              <h2>{{header}}</h2>
                            </div>
                          </md-toolbar>
                          <md-dialog-content style="height: 100%;">
                            <div>
                              <md-select data-cy="session-select" ng-model="selectedSession" required>
                                <md-option
                                  ng-value="option"
                                  ng-repeat="option in options">
                                  {{option.text}}
                                </md-option>
                              </md-select>
                            </div>
                          </md-dialog-content>
                          <md-dialog-actions>
                            <md-button
                              translate="WF.CANCEL"
                              ng-click="cancel()"
                              class="md-raised md-warn"
                            >
                            </md-button>
                            <md-button
                            ng-disabled="!selectedSession"
                              ng-click="save()"
                              class="md-raised md-primary"
                              translate="WF.OK"
                            >
                            </md-button>
                          </md-dialog-actions>
                      </md-dialog>`,
      parent: angular.element(document.body),
      targetEvent: '',
      locals: {
        options: vm.sessionOptions.filter(option =>
          sessionIds.includes(option.value)
        )
      },
      clickOutsideToClose: false,
      escapeToClose: false,
      multiple: true,
      fullscreen: true
    })
  }

  vm.applyDevicesBulkAction = async function applyDevicesBulkAction (res) {
    const sessionId = res
    let sourceSessionId = null
    const filteredStocks = stocks.filter(
      stock =>
        vm.selectedDevice[stock.id] && !vm.devicesById[stock.id].activeUsersNow
    )
    const stockIds = filteredStocks.map(stock => stock.id)
    if (sessionId === 'clone') {
      const sessionIds = filteredStocks.map(
        stock => stock.workflowSessionItemId
      )
      if (
        stockIds.length > 1 &&
        !sessionIds.every(sessionId => sessionId === sessionIds[0])
      ) {
        sourceSessionId = await chooseSourceSession(sessionIds)
      } else {
        sourceSessionId = sessionIds[0]
      }
    }
    $rootScope.loadingProgress = true
    await $scope.changeStockSession(stockIds, sessionId, sourceSessionId)
  }
  vm.applyBulkAction = (type = 'valid') => {
    let sessionIds = []
    let unitIds = []
    const selectedUnits =
      type === 'valid' ? vm.selectedValidUnit : vm.selectedInvalidUnit
    const bulkAction =
      type === 'valid' ? vm.validBulkAction : vm.invalidBulkAction
    const filteredUnits =
      type === 'valid'
        ? units.filter(unit => unit.validQuantity === unit.quantity)
        : units.filter(unit => unit.validQuantity !== unit.quantity)
    if (filteredUnits.length === 1) {
      unitIds = [filteredUnits[0].id]
      sessionIds = [...unitIds]
    } else {
      unitIds = Object.keys(selectedUnits).filter(key => selectedUnits[key])
      if (unitIds.length > 0) {
        sessionIds = unitIds
      }
    }
    if (unitIds.length === 0) {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body',
          theme: 'error-toast',
          hideDelay: 3000
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('WO.SELECT_AT_LEAST_ONE_ROW')
      )
    } else if (bulkAction === 'start') {
      let validateNodesPathEquality = true
      if (sessionIds.length > 1) {
        validateNodesPathEquality = checkThatNodesPathIsEqual(
          sessions,
          sessionIds
        )
      }
      if (!validateNodesPathEquality) {
        $rootScope.showErrorToast(
          $translate.instant('WO.ERRORS.MULTI_SESSION_DIFFERENT_NODES_PATH')
        )
      } else {
        $state.go('app.workorder.workflowSession', {
          id: vm.workorder.id,
          sessionIds
        })
      }
    } else if (bulkAction === 'print') {
      printSession(sessionIds)
    }
  }

  function compareNodesPath (path1, path2) {
    // Check if the second array is a subsequence of the first array
    for (let i = 0; i < path1.length; i++) {
      const node1 = path1[i]
      const node2 = path2[i]
      // Check that the nodeId field is the same
      if (node1.nodeId !== node2.nodeId) {
        return false
      }
    }
    // If all fields match, the nodesPath is the same
    return true
  }
  const checkThatNodesPathIsEqual = function checkThatNodesPathIsEqual (
    sessions,
    selectedSessionIds
  ) {
    const selectedSessions = sessions.filter(session =>
      selectedSessionIds.includes(session.id)
    )
    const sortedSessions = _.cloneDeep(selectedSessions).sort(function (
      session1,
      session2
    ) {
      const session1Length = session1.nodesPath?.length || 0
      const session2Length = session2.nodesPath?.length || 0
      return session1Length - session2Length
    })
    for (let i = 1; i < sortedSessions.length; i++) {
      const session1 = sortedSessions[i - 1]
      const session2 = sortedSessions[i]
      if (!session1.nodesPath) {
        return true
      }
      if (session1.nodesPath && !session2.nodesPath) {
        return true
      }
      if (!compareNodesPath(session1.nodesPath, session2.nodesPath)) {
        return false
      }
    }
    return true
  }

  $scope.deleteSession = function deleteSession (workflowSessionItemId) {
    DialogService.deleteDialog(
      $translate.instant(
        'WO.DELETE_STOCK_WARNING',
        {},
        null,
        null,
        'sceParameters'
      ),
      'delete'
    ).then(
      async function () {
        $rootScope.loadingProgress = true
        try {
          await Workorder.deleteLocalUnit({
            workflowSessionItemId
          }).$promise
          $window.location.reload()
        } catch (err) {
          $rootScope.loadingProgress = false
          console.error(err)
          let message = 'NP-1010'
          if (err?.data?.error?.details?.stockDetails) {
            message = $translate.instant(err?.data?.error?.code, {
              stockDetails: err?.data?.error?.details?.stockDetails
            })
          }
          $rootScope.showErrorToast(message)
        }
      },
      function () {}
    )
  }

  $scope.deleteStock = function deleteStock (stockId) {
    DialogService.deleteDialog(
      $translate.instant(
        'WO.DELETE_STOCK_WARNING',
        {},
        null,
        null,
        'sceParameters'
      ),
      'delete'
    ).then(
      async function () {
        try {
          $rootScope.loadingProgress = true
          await Stock.deleteStock({
            stockId,
            checkWorkorderDeletion: false
          }).$promise
          $window.location.reload()
        } catch (err) {
          $rootScope.loadingProgress = false
          console.error(err)
          const error = [
            'PartAssembly.STOCK_MANAGEMENT_PAGE.CANNOT_DELETE_LAST_STOCK',
            'PartAssembly.STOCK_MANAGEMENT_PAGE.STOCK_IN_USE',
            'PartAssembly.STOCK_MANAGEMENT_PAGE.LOT_STOCK_IN_USE',
            'PartAssembly.STOCK_MANAGEMENT_PAGE.STOCK_WITH_STOCK_ITEMS',
            'PartAssembly.STOCK_MANAGEMENT_PAGE.STOCK_NOT_LOCAL',
            'DELETE_STOCK_WITH_REPORTED_ACTIONS'
          ].includes(err.data.error.code)
            ? `${err.data.error.code}`
            : `WO.ERRORS.STOCK_DELETE_FAILED`
          $rootScope.showErrorToast(
            $translate.instant(
              error,
              err?.data?.error?.details ? err.data.error.details : {}
            ),
            true
          )
        }
      },
      function () {}
    )
  }

  $scope.refreshWorkorderFromSource =
    async function refreshWorkorderFromSource () {
      vm.loadingProgress = $rootScope.loadingProgress = true
      try {
        const res = await Workorder.refreshWorkorderFromSource({
          workorderId: vm.workorder.id
        }).$promise
        vm.loadingProgress = $rootScope.loadingProgress = false
        if (res?.updated?.success) {
          $state.go(
            'app.workorder.show',
            { id: vm.workorder.id },
            { reload: true }
          )
        } else {
          $mdToast.show(errorToast)
          $mdToast.updateTextContent(res?.updated?.msg)
        }
      } catch (err) {
        console.error(err)
        vm.loadingProgress = $rootScope.loadingProgress = false
        $rootScope.showErrorToast('NP-1030')
      }
    }

  const checkWorkorderValidation = function checkWorkorderValidation () {
    if (vm.workorder.quantity < 1) {
      $mdToast.show(errorToast)
      $mdToast.updateTextContent(
        $translate.instant('WO.ERRORS.QUANTITY_LOWER_THAN_ONE')
      )
      return false
    }
    return true
  }

  vm.saveWorkorder = async function saveWorkorder () {
    vm.loadingProgress = true
    const isValid = checkWorkorderValidation()
    if (!isValid) {
      vm.loadingProgress = false
      return
    }
    const fieldsForSave = [
      'expectedDate',
      'quantity',
      'name',
      'fixDescription',
      'faultDescription',
      'malfunctionCode'
    ]
    const workorderCustomFields = FormlyHelper.getCustomFields(Workorder)
    workorderCustomFields.forEach(field => {
      fieldsForSave.push(field.key)
    })
    try {
      await Workorder.saveLocalWorkorder({
        workorderId: vm.workorder.id,
        changes: _.pick(vm.workorder, fieldsForSave)
      }).$promise
      vm.loadingProgress = false
      $state.go('app.workorder.show', { id: vm.workorder.id }, { reload: true })
    } catch (ex) {
      const errorMessage = ex?.data?.error?.message || 'Unexpected exception'
      $mdToast.show(errorToast)
      $mdToast.updateTextContent($translate.instant(errorMessage))
      vm.loadingProgress = false
    }
  }

  const saveExpectedDateForManagerWorkorders =
    function saveExpectedDateForManagerWorkorders (date) {
      const changes = { expectedDate: date }
      // eslint-disable-next-line
      Workorder.saveLocalWorkorder({
        workorderId: vm.workorder.id,
        changes
      }).$promise
    }

  $scope.saveDebouncedFn = _.debounce(saveExpectedDateForManagerWorkorders, 800)

  const openFaultForm = function openFaultForm (
    form,
    sessionIds,
    sessionData,
    formFieldIds,
    usersNames
  ) {
    $rootScope.loadingProgress = false
    $mdDialog
      .show({
        multiple: true,
        locals: {
          workflow: null,
          form,
          recordId: sessionData.workflowRecordId,
          sessionIds,
          sessionData,
          usersById: usersNames
        },
        resolve: {
          ResolvedFields: (Field, MultiTranslateService, $rootScope) =>
            Field.getFieldsWithInnerFields({
              fieldIds: formFieldIds
            }).$promise.then(async res => {
              const fields = res
              if (form.id === FAILURE_FORM_DETAILS.FAILURE_REPORT_ID) {
                const failureFields = await FormUtils.getFailureFields()
                fields.push(...failureFields)
              }
              return fields.map(field =>
                MultiTranslateService.getForView(
                  Field,
                  $rootScope.currentLang,
                  field
                )
              )
            })
        },
        fullscreen: true,
        controller: WorkflowFormListController,
        template: require('../../workflow/show/dialogs/workflow.form.list.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        clickOutsideToClose: false,
        scope: $scope.$new(true)
      })
      .then(res => {
        if (res && Object.keys(res).length > 0) {
          $state.go(
            'app.workorder.show',
            { id: vm.workorder.id },
            { reload: true }
          )
        }
      })
  }
  const reportInvalid = async function reportInvalid (stockId) {
    $rootScope.loadingProgress = true
    const form = await Form.findOne({
      filter: {
        where: {
          id: FAILURE_FORM_DETAILS.FAILURE_REPORT_ID
        }
      }
    }).$promise
    const formFieldIds = form.fields.map(field => field.fieldId)
    const users = await UserModel.find({
      filter: {
        fields: {
          id: true,
          displayName: true
        }
      }
    }).$promise
    const usersNames = {}
    users.forEach(user => {
      usersNames[user.id] = user.displayName
    })
    const stock = vm.stocksById[stockId]
    const sessionData = {
      openInPreview: false,
      sessionNodeId: null,
      nodeId: null,
      nodeName: null,
      workflowId: vm.workorder.workflowId,
      workflowName: vm.workorder.workflowName,
      workflowVersion: null,
      recordId: vm.workorder.workflowRecordId,
      partSku: vm.workorder.sku,
      partRev: vm.workorder.productRev,
      originalWorkflowId: vm.workorder.workflowId,
      originalNodeId: null,
      serial: stock.serial,
      workorderId: vm.workorder.id || null,
      workorderNumber: vm.workorder.workorderNumber || null,
      orderNumber: vm.workorder.orderNumber || null,
      accountName: vm.workorder.customer?.accountName || null,
      createStock: vm.workorder.createStock || false,
      bulkProduction: vm.workorder.bulkProduction || false,
      currentQuantity: stock.quantity,
      stocks: [stock].map(stock => {
        stock.session = stock.workflowSessionItem
        return stock
      }),
      isSerial: vm.workorder.isSerial,
      sessions: [vm.sessionsById[stock.workflowSessionItemId]],
      stockId: stock.id,
      sessionId: stock.workflowSessionItemId
    }
    await openFaultForm(
      form,
      [stock.workflowSessionItem.id],
      sessionData,
      formFieldIds,
      usersNames
    )
  }
  const reportValid = async function reportValid (id, isSession = false) {
    await Workorder.reportSessionIsValid({
      id,
      workorderId: vm.workorder.id,
      isSession
    })
      .$promise.then(async function (res) {
        if (res) {
          $state.go(
            'app.workorder.show',
            { id: vm.workorder.id },
            { reload: true }
          )
        }
      })
      .catch(err => {
        console.error(err)
        const error = [
          'STOCK_HAS_INVALID_ACTIONS_CANT_BE_VALID',
          'VALID_MISSING_KIT_ITEMS',
          'CANT_REPORT_SESSION_VALID_ON_PRODUCTION_WORKORDER'
        ].includes(err.data.error.code)
          ? `WO.ERRORS.${err.data.error.code}`
          : `WO.ERRORS.PRODUCTION_STATUS_CHANGE_FAILED`
        $rootScope.showErrorToast(
          $translate.instant(
            error,
            err?.data?.error?.details ? err.data.error.details : {}
          ),
          true
        )
        if (isSession) {
          const oldSession = vm.unitsById[id]
          oldSession.productionStatus = 'invalid'
          updateRowInKendoByParameters(
            id,
            ['productionStatus'],
            'invalidUnitsKendoGrid'
          )
        }
        const oldStock = vm.devicesById[id]
        oldStock.productionStatus = 'invalid'
        updateRowInKendoByParameters(
          id,
          ['productionStatus'],
          'devicesKendoGrid'
        )
      })
  }

  $scope.editStockQuantity = async function editStockQuantity (stockId) {
    $scope.loadingProgress = true
    const stock = vm.devicesById[stockId]
    const res = await WorkorderUtils.splitItemsDialog(
      [stock],
      vm.workorder.sku,
      'edit'
    )
    if (res && res[0]) {
      const stockData = res[0]
      const quantityDifference =
        stockData.newStockQuantity - vm.devicesById[stockId].quantity
      Stock.editStockQuantity({
        stockId,
        quantity: stockData.newStockQuantity
      })
        .$promise.then(stock => {
          if (['priority', 'sap'].includes(vm.workorder.source)) {
            $state.go(
              'app.workorder.show',
              { id: vm.workorder.id },
              { reload: true }
            )
          } else {
            const oldStock = vm.devicesById[stockId]
            oldStock.quantity = stock.quantity
            oldStock.validQuantity = stock.validQuantity
            oldStock.invalidQuantity = stock.invalidQuantity
            vm.stocksById[stockId] = oldStock
            const unit = vm.unitsById[stock.workflowSessionItemId]
            unit.quantity = unit.quantity + quantityDifference
            if (stock.productionStatus === 'valid') {
              unit.validQuantity = unit.validQuantity + quantityDifference
            } else {
              unit.invalidQuantity = unit.invalidQuantity + quantityDifference
            }

            updateRowInKendoByParameters(
              stockId,
              ['quantity', 'validQuantity', 'invalidQuantity'],
              'devicesKendoGrid'
            )
            let kendoPath = 'validUnitsKendoGrid'
            if (stock.productionStatus === 'invalid') {
              kendoPath = 'invalidUnitsKendoGrid'
            }
            updateRowInKendoByParameters(
              stock.workflowSessionItemId,
              ['quantity', 'validQuantity', 'invalidQuantity'],
              kendoPath
            )

            compareQuantities()
            $scope.loadingProgress = false
          }
        })
        .catch(err => {
          $scope.loadingProgress = false
          sessionErrorHandler(err)
        })
    } else {
      $scope.loadingProgress = false
      $rootScope.showErrorToast(
        $translate.instant('WO.ERRORS.STOCK_QUANTITY_CANNOT_BE_ZERO'),
        true
      )
    }
  }

  const compareQuantities = function compareQuantities () {
    vm.currentQuantity = 0
    Object.values(vm.unitsById).forEach(unit => {
      vm.currentQuantity += unit.quantity
    })
    vm.quantityDifference = vm.currentQuantity - vm.workorder.quantity
  }

  $scope.changeProductionStatus = async function (
    id,
    productionStatus,
    isSession = false
  ) {
    $scope.loadingProgress = true
    if (productionStatus === 'invalid') {
      if (!isSession) {
        await reportInvalid(id)
      }
    } else {
      await reportValid(id, isSession)
    }
    $scope.loadingProgress = false
  }
  $scope.viewPriorityWorkorderSubForms =
    async function viewPriorityWorkorderSubForms () {
      return $mdDialog.show({
        controller: workorderSubformsDialogController,
        template: require('../subforms/workorder-subforms.dialog.template.html'),
        parent: angular.element(document.body),
        locals: {
          workorderNumber: vm.workorder.workorderNumber
        },
        resolve: {
          ResolvedSubforms: function (Priority) {
            return Priority.getWorkorderSubforms({
              workorderNumber: vm.workorder.workorderNumber
            }).$promise
          }
        },
        multiple: true,
        fullscreen: true,
        escapeToClose: true,
        clickOutsideToClose: false
      })
    }

  $scope.editSessionName = async function editSessionName (sessionId) {
    return $mdDialog
      .show({
        controller: function ($scope, locals) {
          $scope.name = locals.name
          $scope.header = $translate.instant('WO.SESSION_NAME')
          $scope.cancel = function cancel () {
            $mdDialog.cancel()
          }
          $scope.save = function save () {
            $mdDialog.hide($scope.name)
          }
        },
        template: `<md-dialog >
                          <md-toolbar>
                            <div class="md-toolbar-tools">
                              <h2>{{header}}</h2>
                            </div>
                          </md-toolbar>
                          <md-dialog-content style="height: 100%; ">
                            <md-input-container style="width: 100%;">
                              <input  ng-model="name"/>
                            </md-input-container>
                          </md-dialog-content>
                          <md-dialog-actions>
                            <md-button
                              translate="WF.CANCEL"
                              ng-click="cancel()"
                              class="md-raised md-warn"
                            >
                            </md-button>
                            <md-button
                              ng-click="save()"
                              class="md-raised md-primary"
                              translate="WF.OK"
                            >
                            </md-button>
                          </md-dialog-actions>
                      </md-dialog>`,
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {
          name: vm.unitsById[sessionId].name
        },
        clickOutsideToClose: false,
        escapeToClose: false,
        multiple: true,
        fullscreen: true
      })
      .then(async function (name) {
        await WorkflowSessionItem.updateSessionName({
          workflowSessionItemId: sessionId,
          name
        }).$promise
        vm.unitsById[sessionId].name = name
        if (vm.unitsById[sessionId].validQuantity > 0) {
          updateRowInKendoByParameters(sessionId, ['name'])
        } else {
          updateRowInKendoByParameters(
            sessionId,
            ['name'],
            'invalidUnitsKendoGrid'
          )
        }
        createSessionOptions()
      })
  }
  const menu = []
  if (vm.workorder.source === 'priority') {
    menu.push(
      ...[
        {
          content: `<md-button ng-click="refreshWorkorderFromSource()" data-testid="refresh-priority-workorder">
                <md-icon md-font-icon="icon-cached"></md-icon>
                <span translate="WO.REFRESH_WORKORDER"></span>
              </md-button>`
        },
        {
          content: `<md-button ng-click="viewPriorityWorkorderSubForms()" data-testid="view-priority-workorder-subforms">
                <md-icon md-font-icon="icon-list-box-outline"></md-icon>
                <span translate="WO.VIEW_PRIORITY_WORKORDER_SUBFORMS.MENU_TEXT"></span>
              </md-button>`
        }
      ]
    )
  }

  if ($rootScope.appSettings.priorityLabels.Workorder > 0) {
    menu.push({
      content: `<md-button ng-click="printWorkorder()" data-testid="print-workorder">
              <md-icon md-font-icon="icon-printer"></md-icon>
              <span translate="COMMON.PRINT"></span>
            </md-button>`
    })
  }

  $scope.headerOptions = {
    icon: 'icon-file-document-box',
    postTitleHtml:
      $scope.isLocalWorkorder ||
      (vm.workorder.source === 'priority' &&
        vm.workorder.externalType === 'serviceCall')
        ? `<div layout="row" layout-align="end center">
                      <md-button data-testid="workorder-local-save" ng-disabled="$root.loadingProgress" ng-if="vm.saveTrigger"
                                  class="md-raised" ng-click="vm.saveWorkorder()">
                        <md-icon md-font-icon="icon-content-save"></md-icon>
                        <span translate="BUTTONS.SAVE"></span>
                      </md-button>
                    </div>`
        : '',
    template: require('../../../../templates/headers/simple.html'),
    titleClass: 'forceDirLTR',
    title: `${vm.workorder.workorderNumber}`,
    menuHideExpression: () => menu.length === 0,
    // !$scope.isManagerWorkorder && vm.workorder.source !== 'priority',
    menu,
    subtitleHtml: `
      <div layout="row">
        <a ng-bind="vm.workorder.sku" ng-click="showPart(vm.workorder.sku, vm.workorder.productRev)"></a>
        <div class="product-rev" ng-if="vm.workorder.productRev">&nbsp
          ({{ 'WF.SESSION_HEADER.REVISION' | translate }}: {{ vm.workorder.productRev }})
        </div>
      </div>`,
    fabButton: {}
  }
}

module.exports = { /* draggable, */ WorkorderShowController, dynamic }

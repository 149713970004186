/* global _ */
require('../form.scss')
const {
  FAILURE_FORM_DETAILS
} = require('../../../../../../../common/constants/form-constants.json')
const UUID = require('uuid')

/** @ngInject */
function FormReviewController (
  $scope,
  $rootScope,
  $state,
  $translate,
  $mdToast,
  ResolvedData,
  FormData,
  FormUtils,
  Page,
  PermissionUtils,
  $mdDialog,
  $stateParams,
  $window,
  sessionData,
  isDisabled,
  $timeout,
  Workorder,
  PartViewHelper,
  PanelHelper,
  nextplusSocket,
  ResolvedForms,
  parentLinkedForm,
  DialogService
) {
  if (!ResolvedData || !ResolvedData.formDatas || !ResolvedData.form) {
    return $state.go('app.404')
  }
  let isStocks = false

  const { formDatas, form, fields, users, workflows } = ResolvedData
  $scope.printForm = function printForm () {
    $window.print()
    if ($stateParams.print) {
      $window.close()
    }
  }
  $scope.dialogPrintForm = function dialogPrintForm () {
    const formData = $scope.formModels[$scope.unitId]
    $window.open(
      $state.href('app.form.display', { id: formData.id, print: true })
    )
  }

  $scope.deleteFormData = function deleteFormData () {
    if ($scope.formDatas.length > 0) {
      DialogService.deleteDialog(
        $translate.instant(
          'FORM.DELETE_MESSAGE',
          {},
          null,
          null,
          'sceParameters'
        )
      ).then(
        function () {
          FormData.destroyById({ id: $scope.formDataId })
            .$promise.then(async () => {
              if ($scope.sessionData) {
                $mdDialog.hide()
              } else {
                $state.go('app.reports.form', { id: $scope.form.id })
              }
            })
            .catch(e => console.error(e))
        },
        function () {}
      )
    }
  }

  $scope.formLoaded = () => {
    if ($stateParams.print) {
      $timeout($scope.printForm, 0)
    }
  }
  $scope.activeLevelByUnitId = {}
  $scope.userDecisionByUnitId = {}
  $scope.canReviewByUnitId = {}
  const initReviewPage = async function initReviewPage (unitId) {
    const formData = $scope.formModels[unitId]
    $scope.userDecisionByUnitId[unitId] = {
      userId: $rootScope.currentUser.id,
      comment: ''
    }
    if (Object.keys(formData).length > 0) {
      const { approvalWorkflow } = formData
      if (approvalWorkflow) {
        $scope.activeLevelByUnitId[unitId] =
          approvalWorkflow.levels.find(level => !!level.start && !level.end) ||
          null
      }
      $scope.canReviewByUnitId[unitId] = false
      if ($scope.activeLevelByUnitId[unitId]) {
        $scope.canReviewByUnitId[unitId] = await FormUtils.isPermitForReview(
          $scope.form,
          formData,
          $scope.activeLevelByUnitId[unitId].id
        )
      }
    }
    if (
      unitId !== 'constant' &&
      !$rootScope.appSettings.allowMultiUsersInSession
    ) {
      nextplusSocket.angularSocket.on('lockedSessions', data => {
        $scope.formExtras[unitId].lockedSessions = data[unitId]
          ? data[unitId]
          : false
        $scope.formExtras[unitId].runDetailsFieldsExpression()
      })

      $scope.readLockedSessions = () => {
        nextplusSocket.socket.emit('lockedSessions')
        $scope.socketLockTimeout = $timeout($scope.readLockedSessions, 2000)
      }

      $scope.readLockedSessions()
    }
  }

  $scope.tabSelected = unitId => {
    $scope.unitId = unitId
    if (isStocks) {
      $scope.sessionId = $scope.sessionDataByUnitId[unitId].sessionId
    } else {
      $scope.sessionId = unitId
    }
  }
  $scope.thisFormIsReady = unitId => {
    if (unitId && $scope.formExtras[unitId]) {
      return !!$scope.formExtras[unitId].formReady
    } else {
      return false
    }
  }
  $scope.thereAreMultipleFormsReady = () => {
    const formsReady = $scope.unitIds.filter(
      unitId => $scope.formExtras[unitId].formReady
    )
    return formsReady.length > 1
  }

  $scope.thereIsFormWithNoApprovalWorkflow = () => {
    if ($scope.form.previewMode) return false
    return $scope.unitIds.find(
      unitId => !$scope.formExtras[unitId].approvalWorkflow
    )
  }

  const rerenderForm = async function rerenderForm (unitId) {
    const { formData, users } = await FormData.findInstance({
      where: { id: $scope.formModels[unitId].id }
    }).$promise

    $scope.users = users
    $scope.formModels[unitId] = formData
    initReviewPage(unitId)
    await $scope.formExtras[unitId].initDirective(formData, users)
  }

  $scope.reviewLevel = async function reviewLevel (action, unitId) {
    $rootScope.loadingProgress = true
    try {
      const formData = $scope.formExtras[unitId].getFormObject()
      await FormData.reviewLevel({
        formDataId: formData.id,
        levelId: $scope.activeLevelByUnitId[unitId].id,
        action,
        comment: $scope.userDecisionByUnitId[unitId].comment
      }).$promise
      $scope.userDecisionByUnitId[unitId].comment = '' // reset
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '#content',
          theme: 'success-toast',
          hideDelay: 2500
        })
      )
      $mdToast.updateTextContent($translate.instant('FORM.LEVEL.REVIEW_SEND'))
      $rootScope.loadingProgress = false
      await rerenderForm(unitId)
    } catch (err) {
      console.error(err)
      const {
        data: {
          error: { code }
        }
      } = err
      $rootScope.loadingProgress = false
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '#content',
          theme: 'error-toast',
          hideDelay: 2500
        })
      )
      $mdToast.updateTextContent($translate.instant(code))
      if (code === 'FORM.ERROR.LEVEL.FORM_ALREADY_REJECTED') {
        await rerenderForm(unitId)
      }
    }
  }
  const isValid = async function isValid (saveAll, currentUnitId) {
    console.log('isValid', saveAll, currentUnitId)
    if (saveAll) {
      for (let k = 0; k < $scope.unitIds.length; k++) {
        const unitId = $scope.unitIds[k]
        if ($scope.formExtras[unitId].formReady) {
          const validFormData = $scope.formExtras[unitId].checkValidation({
            stock: $scope.displayNameByUnitId[unitId]
          })
          if (!validFormData) return false
        }
      }
    } else {
      if ($scope.formExtras[currentUnitId].formReady) {
        const validFormData = $scope.formExtras[currentUnitId].checkValidation({
          stock: $scope.displayNameByUnitId[currentUnitId]
        })
        if (!validFormData) return false
      }
    }
    return true
  }
  $scope.save = async function save (
    submitForApproval = false,
    saveAll,
    currentUnitId
  ) {
    $scope.isSaving = true
    let showSuccess = true
    const mdToastError = $mdToast.nextplus({
      position: $rootScope.toastLocation,
      parent: 'document.body',
      theme: 'error-toast',
      hideDelay: 2500
    })

    const ifFormValid = await isValid(saveAll, currentUnitId)
    if (ifFormValid) {
      let isEdit = false
      $scope.formExtras.submitForApproval = submitForApproval
      const formDatas = []
      let unitIds = $scope.unitIds
      if (!saveAll) {
        unitIds = [currentUnitId]
      }
      for (let k = 0; k < unitIds.length; k++) {
        const unitId = unitIds[k]
        const formData = $scope.formExtras[unitId].getFormObject()
        isEdit = !!(k === 0 && $scope.formDataIds[unitId]) || false
        if (!formData) continue
        let serialField, removeStockItemsField, changeSerialField, quantity
        try {
          if (formData.formId === FAILURE_FORM_DETAILS.FAILURE_REPORT_ID) {
            formData.fields.forEach(field => {
              if (
                field.id ===
                FAILURE_FORM_DETAILS.FAILURE_NEW_SERIAL_NUMBER_FIELD_ID
              ) {
                serialField = field.value || ''
              } else if (
                field.id ===
                FAILURE_FORM_DETAILS.FAILURE_REMOVE_STOCK_ITEMS_FIELD_ID
              ) {
                removeStockItemsField = field.value || false
              } else if (
                field.id ===
                FAILURE_FORM_DETAILS.FAILURE_CHANGE_SERIAL_NUMBER_FIELD_ID
              ) {
                changeSerialField = field.value || false
              } else if (
                field.id === FAILURE_FORM_DETAILS.FAILURE_QUANTITY_FIELD_ID
              ) {
                quantity = field.value || 1
              }
            })
            const faultValidateObject = {
              sessionId: $scope.sessionId,
              serial: serialField || '',
              removeStockItemsField,
              changeSerialField,
              quantity: quantity || 1
            }
            if ($scope.sessionDataByUnitId[unitId].stockId) {
              faultValidateObject.stockId =
                $scope.sessionDataByUnitId[unitId].stockId
            }
            if ($scope.sessionDataByUnitId[unitId].workorderId) {
              faultValidateObject.workorderId =
                $scope.sessionDataByUnitId[unitId].workorderId
            }
            await Workorder.faultSessionValidateFields(faultValidateObject)
              .$promise
          }
          if (submitForApproval === true) {
            const approvalValid = await FormUtils.checkForApprovalManagerError(
              formData.formId,
              formData.ownerId
            )
            if (!approvalValid) {
              showSuccess = false
              return
            }
          }
          const formDataId = await $scope.formExtras[unitId].save(false)
          if (submitForApproval && formDataId) {
            $scope.formDataIds[unitId] = formDataId
            await FormData.prototype$startApprovalProcess({
              id: formDataId
            }).$promise
          }
          formDatas.push(formData)
        } catch (err) {
          $scope.isSaving = false
          showSuccess = false
          console.log(
            `Form ${$scope.form.name} - unit id '${unitId}' save error`,
            err
          )
          let showToast = false
          if (err) {
            if (formData.formId === FAILURE_FORM_DETAILS.FAILURE_REPORT_ID) {
              if (err?.data?.error) {
                showToast = true
                if (err?.data?.error?.details) {
                  $mdToast.updateTextContent(
                    $translate.instant(
                      'WF.ERRORS.' + err.data.error.code,
                      err.data.error.details
                    )
                  )
                } else {
                  $mdToast.updateTextContent(
                    $translate.instant('WF.ERRORS.' + err.data.error.message)
                  )
                }
              }
            } else {
              showToast = true
              $mdToast.updateTextContent(err)
            }
          } else {
            showToast = true
            $mdToast.updateTextContent(
              $translate.instant('FORM.TRIGGERS_DIALOG.SAVED_CANCELED')
            )
          }
          if (showToast) {
            $mdToast.show(mdToastError)
          }
          return
        }
      }
      if (showSuccess) {
        $scope.isSaving = false
        $mdToast.show(
          $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'document.body',
            theme: 'success-toast',
            hideDelay: 2500
          })
        )
        $mdToast.updateTextContent(
          $translate.instant('FORM.SUCCESS.FORM_SAVED')
        )
        if ($scope.sessionData || $scope.parentLinkedForm) {
          $mdDialog.hide(formDatas)
        } else {
          if (isEdit) {
            await rerenderForm($scope.unitIds[0])
          } else {
            $state.go('app.reports.form', { id: $scope.form.id })
          }
        }
      }
    } else {
      $scope.isSaving = false
    }
  }
  $scope.cancel = async () => $mdDialog.cancel()

  $scope.copyValues = unitId => {
    const fieldIds = $scope.form.fields.map(field => field.id)
    const formModelsObject = $scope.formModels[unitId]
    const canCopyStatus =
      ($scope.formModels[unitId].oldStatus &&
        $scope.formModels[unitId].oldStatus !== 'close') ||
      ($scope.canClose && $scope.formModels[unitId].oldStatus === 'close')
    const formlyPictureObjects = Object.keys(formModelsObject).filter(key =>
      key.includes('Object')
    )
    const formValid = $scope.formExtras[unitId].isFormValid()

    for (let i = 0; i < $scope.unitIds.length; i++) {
      const otherUnitId = $scope.unitIds[i]
      if (otherUnitId !== unitId) {
        if (canCopyStatus) {
          $scope.formModels[otherUnitId].status = formModelsObject.status
        }
        $scope.formModels[otherUnitId].assignee = formModelsObject.assignee
        fieldIds.forEach(fieldId => {
          if (
            Array.isArray(formModelsObject[fieldId]) &&
            formModelsObject[fieldId]?.[0]?.id
          ) {
            $scope.formModels[otherUnitId][fieldId] = formModelsObject[
              fieldId
            ].map(item => ({ ...item, id: UUID() }))
          } else {
            $scope.formModels[otherUnitId][fieldId] = formModelsObject[fieldId]
          }
        })
        formlyPictureObjects.forEach(key => {
          $scope.formModels[otherUnitId][key] = formModelsObject[key]
        })
        $scope.formExtras[otherUnitId].formReady =
          $scope.formExtras[unitId].formReady

        $scope.formExtras[otherUnitId].setFormValid(formValid)
      }
    }
  }

  $scope.panelRefs = {}
  const fillPanelRefObject = function fillPanelRefObject (
    operation,
    name,
    panelRef
  ) {
    switch (operation) {
      case PanelHelper.OPERATIONS.OPEN:
        $scope.panelRefs[name] = panelRef
        break
      case PanelHelper.OPERATIONS.CLOSE:
        delete $scope.panelRefs[name]
        break
    }
  }
  $scope.showPart = function showPart (partNumber, partRev = null) {
    PartViewHelper.openPartViewDialog(partNumber, partRev, {
      fullScreen: true,
      savePanelRefFunction: fillPanelRefObject
    })
  }
  $scope.$on('$destroy', () => {
    if (Object.keys($scope.panelRefs).length > 0) {
      Object.values($scope.panelRefs).forEach(panelRef => panelRef.destroy())
    }
  })

  const init = async function init () {
    $scope.activeLevelBySessionId = {}
    $scope.userDecisionBySessionId = {}
    $scope.canReviewBySessionId = {}
    $scope.form = form
    $scope.formDatas = formDatas
    $scope.formDatasNumbers = formDatas.map(fd => fd.number).join(', ')
    $scope.formDatasSerials = formDatas.map(fd => fd.serial).join(', ')
    $scope.sessions = []
    if ($scope.formDatas.length > 0) {
      $scope.formDatas.forEach(formData => {
        if (formData.sessionId && formData.sessionId !== '-1') {
          $scope.sessions.push({
            id: formData.sessionObject.id,
            serial: formData.sessionObject.serials.find(
              serial => serial === formData.serial
            ),
            recordId: formData.sessionObject.recordId
          })
        }
      })
    }

    $scope.users = users
    $scope.workflows = workflows
    $scope.fields = fields
    $scope.sessionData = sessionData
    $scope.formDataId = formDatas.length === 1 ? formDatas[0].id : null
    $scope.titleInfo = formDatas.length > 0 ? formDatas[0] : $scope.sessionData

    const weightForms = _.sortBy(
      ResolvedForms.filter(
        form => form.weight !== null && form.weight !== undefined
      ),
      'weight'
    )

    // Sort forms without weight by name
    const nonWeightForms = _.sortBy(
      ResolvedForms.filter(
        form => form.weight === null || form.weight === undefined
      ),
      form => form.name.toLowerCase()
    )

    $scope.allForms = weightForms.concat(nonWeightForms).map(form => {
      form.hasPermission = FormUtils.isPermit('view', form)
      return form
    })
    $scope.parentLinkedForm = parentLinkedForm

    const { certificateIds } = $rootScope.currentUser
    $scope.canCreate = FormUtils.isPermit('create', $scope.form, certificateIds)
    $scope.canClose = FormUtils.isPermit('close', $scope.form)
    $scope.hasCreatePermissions = PermissionUtils.isPermit('FormData', 'create')
    $scope.hasEditPermissions = PermissionUtils.isPermit(
      'FormData',
      'patchAttributes'
    )
    $scope.isDisabled = $scope.form.previewMode || isDisabled || false
    if (!$scope.isDisabled) {
      if (formDatas.length === 0) {
        $scope.isDisabled = !$scope.hasCreatePermissions
      } else {
        $scope.isDisabled = !$scope.hasEditPermissions
      }
    }

    $scope.displayNameByUnitId = {}
    $scope.sessionDataByUnitId = {}
    if ($scope.sessionData?.stockId && $scope.sessionData?.stocks?.length > 0) {
      $scope.sessionData.stocks = $scope.sessionData.stocks.filter(stock => {
        const currentStockId = stock.id || stock._id
        return currentStockId === $scope.sessionData.stockId
      })
    }
    // $scope.form.singleton for the tabs. formDatas.length === 0 for new forms
    // (!$scope.sessionData.createStock && $scope.sessionData.isSerial && $scope.sessionData.stocks.length > 0) for non create stock serial
    isStocks =
      $scope.sessionData &&
      (($scope.sessionData?.createStock &&
        ($scope.form.singleton || formDatas.length === 0)) ||
        (!$scope.sessionData.createStock &&
          $scope.sessionData.isSerial &&
          $scope.sessionData.stocks.length > 0))
    $scope.stockIds = []
    if (isStocks) {
      $scope.sessionData.stocks.forEach(stock => {
        const currentStockId = stock.id || stock._id
        const currentSessionId =
          stock?.session?.id || stock?.workflowSessionItemId
        $scope.stockIds.push(currentStockId)
        $scope.displayNameByUnitId[currentStockId] =
          stock.displayName || stock.serial
        $scope.sessionDataByUnitId[currentStockId] = {
          ...$scope.sessionData,
          sessionId: currentSessionId,
          serial: stock?.serial,
          stockId: $scope.sessionData.stockId
            ? $scope.sessionData.stockId
            : currentStockId,
          stockItems: stock?.stockItems
        }
      })
    } else if ($scope.sessionData?.sessions?.length > 0) {
      $scope.sessionData.sessions.forEach(session => {
        $scope.displayNameByUnitId[session.id] = session.displayName
        $scope.sessionDataByUnitId[session.id] = {
          ...$scope.sessionData,
          serial: session?.serials?.length > 0 ? session.serials[0] : null
        }
      })
    }

    $scope.isSaving = false
    $scope.formModels = {}
    $scope.formDataIds = {}
    $scope.formExtras = {}
    const formData = formDatas.length > 0 ? formDatas[0] : $scope.sessionData
    const title = FormUtils.generateFormTitle(
      formData,
      formDatas,
      $scope.form,
      $scope.form.context
    )
    Page.setTitleText(title)

    // sessionId === null --> form was filled in preview mode
    if (
      $scope.sessionData?.sessionIds?.length > 0 &&
      $scope.sessionData?.sessionIds[0] !== null
    ) {
      const unitIds = isStocks ? $scope.stockIds : $scope.sessionData.sessionIds
      $scope.unitIds = unitIds.map(unitId => {
        let formData
        if (isStocks) {
          formData = formDatas.find(fd => fd.originStockId === unitId)
        } else {
          formData = formDatas.find(fd => fd.sessionId === unitId)
        }
        if (formData) {
          $scope.formModels[unitId] = formData
          $scope.formDataIds[unitId] = formData.id
        } else {
          $scope.formModels[unitId] = {}
          $scope.formDataIds[unitId] = null
        }
        $scope.formExtras[unitId] = {
          formReady: false,
          approvalWorkflow: false,
          initReviewPage
        }
        return unitId
      })
    } else {
      const formData = formDatas[0]
      const sessionId = formData?.sessionId ? formData.sessionId : 'constant'
      $scope.unitIds = [sessionId]
      if (formData) {
        $scope.formModels[sessionId] = formData
        $scope.formDataIds[sessionId] = formData.id
      } else {
        $scope.formModels[sessionId] = {}
        $scope.formDataIds[sessionId] = null
      }
      $scope.formExtras[sessionId] = {
        formReady: false,
        approvalWorkflow: false,
        initReviewPage
      }
    }
    $scope.editMode = !_.isEmpty($scope.formDataIds)
    $scope.presaveTriggers = $scope.form.presaveTriggers || []
    $scope.postsaveTriggers = $scope.form.postsaveTriggers || []
    $scope.unitIds.forEach(unitId => {
      initReviewPage(unitId)
    })
    if ($scope.unitIds.length === 1) {
      $scope.unitId = $scope.unitIds[0]
      if (isStocks) {
        $scope.sessionId = $scope.sessionDataByUnitId[$scope.unitId].sessionId
      } else {
        $scope.sessionId = $scope.unitIds[0]
      }
    }
  }

  init()
}
module.exports = FormReviewController

/* global angular */
/** @ngInject */
function config (
  $mdThemingProvider,
  $mdToastProvider,
  fusePalettes,
  fuseThemes
) {
  // Inject Cookies Service
  let $cookies
  angular.injector(['ngCookies']).invoke([
    '$cookies',
    function (_$cookies) {
      $cookies = _$cookies
    }
  ])

  // Check if custom theme exist in cookies
  const customTheme = $cookies.getObject('customTheme')
  if (customTheme) {
    //  fuseThemes['custom'] = customTheme
  }

  // $mdThemingProvider.alwaysWatchTheme(true)

  // Define custom palettes
  angular.forEach(fusePalettes, function (palette) {
    $mdThemingProvider.definePalette(palette.name, palette.options)
  })

  // Register custom themes
  angular.forEach(fuseThemes, function (theme, themeName) {
    $mdThemingProvider
      .theme(themeName)
      .primaryPalette(theme.primary.name, theme.primary.hues)
      .accentPalette(theme.accent.name, theme.accent.hues)
      .warnPalette(theme.warn.name, theme.warn.hues)
      .backgroundPalette(theme.background.name, theme.background.hues)
  })
  $mdThemingProvider.theme('success-toast')
  $mdThemingProvider.theme('error-toast')
  $mdThemingProvider.theme('info-toast')
  $mdThemingProvider.theme('warning-toast')
  let activeToastContent
  let activeToastIcon
  let ACTION_RESOLVE
  /**
   * Controller for the Toast interim elements.
   * @ngInject
   */
  function MdToastController ($mdToast, $scope, $log, $translate) {
    // For compatibility with AngularJS 1.6+, we should always use the $onInit hook in
    // interimElements. The $mdCompiler simulates the $onInit hook for all versions.
    $scope.closeToast = function closeToast () {
      $mdToast.hide()
      console.log('closeToast')
    }
    $scope.closeText = $translate.instant('BUTTONS.CLOSE')
    this.$onInit = function () {
      const self = this

      switch (self.theme) {
        case 'success-toast':
          activeToastIcon = 'check-circle-outline'
          break
        case 'warning-toast':
          activeToastIcon = 'alert-outline'
          break
        case 'error-toast':
          activeToastIcon = 'close-circle-outline'
          break
        case 'info-toast':
          activeToastIcon = 'information-outline'
          break
      }

      if (self.highlightAction) {
        $scope.highlightClasses = ['md-highlight', self.highlightClass]
      }

      // If an action is defined and no actionKey is specified, then log a warning.
      if (self.action && !self.actionKey) {
        $log.warn(
          'Toasts with actions should define an actionKey for accessibility.',
          'Details: https://material.angularjs.org/latest/api/service/$mdToast#mdtoast-simple'
        )
      }

      if (self.actionKey && !self.actionHint) {
        self.actionHint = 'Press Control-"' + self.actionKey + '" to '
      }

      if (!self.dismissHint) {
        self.dismissHint = 'Press Escape to dismiss.'
      }

      $scope.$watch(
        function () {
          return activeToastContent
        },
        function () {
          self.content = activeToastContent
        }
      )
      $scope.$watch(
        function () {
          return activeToastIcon
        },
        function () {
          self.icon = activeToastIcon
        }
      )
      this.resolve = function () {
        $mdToast.hide(ACTION_RESOLVE)
      }
    }
  }
  $mdToastProvider
    .addPreset('nextplus', {
      argOption: 'textContent',
      methods: [
        'textContent',
        'content',
        'action',
        'actionKey',
        'actionHint',
        'highlightAction',
        'highlightClass',
        'theme',
        'parent',
        'dismissHint'
      ],
      options: /* @ngInject */ function ($mdToast, $mdTheming, $translate) {
        return {
          template: `<md-toast md-theme="{{ toast.theme }}" ng-class="{'md-capsule': toast.capsule}">
                          <div flex="1" class="md-toast-content" aria-live="polite" aria-relevant="all">
                          <md-icon flex="none" ng-show="self.icon !== ''" class="s24" md-font-icon="icon-{{toast.icon}}"></md-icon>
                            <div flex="1" ng-bind-html="toast.content" class="md-toast-text">
                            </div>
                              <span class="md-visually-hidden">{{ toast.dismissHint }}</span>
                              <span class="md-visually-hidden" ng-if="toast.action && toast.actionKey">
                                      {{ toast.actionHint }}
                                    </span>
                              <md-button class="md-action" ng-if="toast.action" ng-click="toast.resolve()" ng-class="highlightClasses">
                                  {{ toast.action }}
                              </md-button>
                            <md-button flex="1" ng-click="closeToast()">
                              <span>{{::closeText}}</span>
                            </md-button>
                          </div>
                      </md-toast>`,
          controller: MdToastController,
          theme: $mdTheming.defaultTheme(),
          controllerAs: 'toast',
          bindToController: true
        }
      }
    })
    .addMethod('updateTextContent', updateTextContent)
    .addMethod('updateIcon', updateIcon)
  function updateTextContent (newContent) {
    activeToastContent = newContent
  }
  function updateIcon (newIcon) {
    activeToastIcon = newIcon
  }
}

module.exports = config

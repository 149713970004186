/** @ngInject */
module.exports = function config ($stateProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.settings', {
      abstract: true,
      url: '/settings',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "settings.module" */ './settings.module.js'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      }
    })
    .state('app.settings.system', {
      url: '/show/system',
      views: {
        'content@app': {
          template: require('./system/system.html'),
          controller: 'SettingsSystemController'
        }
      },
      resolve: {
        ResolvedSettings: Setting =>
          Setting.privateSettings({
            settingNames: [
              'deploymentName',
              'aiLLMServiceEnabled',
              'logo',
              'bg',
              'css',
              'language',
              'availableLanguages',
              'contentTranslations',
              'availableContentLanguages',
              'defaultContentLanguage',
              'fallbackTranslations',
              'availableContentPackageTemplate',
              'contentPackageDefaultTemplate',
              'availableWorkflowTemplate',
              'workflowDefaultTemplate',
              'enforceOldRevisionValidation',
              'enforceRevisionValidation',
              'workorderAllowDefaultWorkflow',
              'workorderDefaultWorkflow',
              'timeRecordTypeOptions',
              'defaultSessionRecordType',
              'timeRecordManagerApprovalNeeded',
              'usersToSeeAllTimeRecords',
              'disableRecordsForEdit',
              'endAllOpenTimeLogsTime',
              'timeLogDurationLimit',
              'displayRecordsApprovalPopup',
              'idleFirst',
              'idleSecond',
              'workflowTimer',
              'TOOL_ATTACHEMENT_CHECKLIST',
              'workingWithoutKitRequireApprove',
              'showFinishSessionPopup',
              'showReleaseVersionLog',
              'allowMultiUsersInSession',
              'allowFieldChangeInSignNodes',
              'legacyDescriptionBox',
              'autoAssignUsersToWorkorderOnSign',
              'stopShowReleaseVersionLog',
              'separateToolSerialsWithGroups',
              'customHomePage',
              'standardWorkingDays',
              'workingDayEndTime',
              'timezone',
              'digestEmail',
              'smtp',
              'embeddedSupport',
              'globalSearchOCR',
              'videoAutoplay',
              'storylineEnabled',
              'loginWithCard',
              'supplierWorkorderFreeText',
              'supplierWorkorderAutoLinkToGroups',
              'usersWithoutGroupSeeAll',
              'ALLOW_SEARCH_IN_FILES',
              'sessionRecordingEanble',
              'gaEnable',
              'vendor',
              'dateTimeFormats',
              'ocrAutoSearch',
              'disableEditUsedFields',
              'authenticationTimeout'
            ]
          }).$promise
      }
    })
    .state('app.settings.export', {
      url: '/export',
      views: {
        'content@app': {
          template: require('./export/export.html'),
          controller: 'SettingsExportController'
        }
      }
    })
    .state('app.settings.import', {
      url: '/import',
      views: {
        'content@app': {
          template: require('./import/import.html'),
          controller: 'SettingsImportController'
        }
      }
    })
    .state('app.settings.roles', {
      url: '/roles/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsRolesController',
          controllerAs: 'vm'
        }
      },
      resolve: {}
    })
    .state('app.settings.roles-show', {
      url: '/roles/show/?:id',
      params: {},
      views: {
        'content@app': {
          template: require('./roles/add_edit_role.html'),
          controller: 'SettingsAddEditRolesController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedRole: function (Role, $stateParams) {
          if (!$stateParams.id || $stateParams.id === '') return null
          return Role.get({ id: $stateParams.id }).$promise
        },
        resolvedModels: Rule => Rule.getAllModels().$promise
      }
    })
    .state('app.settings.group', {
      url: '/group/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsGroupController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.break-type', {
      url: '/break-type/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsBreakTypesController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.events', {
      url: '/events/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsEventController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.upload', {
      url: '/show/upload',
      bodyClass: 'upload',
      views: {
        'content@app': {
          template: require('./../../main/file-manager/views/page.html'),
          controller: 'FileDialogController as vm'
        }
      },
      resolve: {
        ResolvedLastSeen: LastSeenResource =>
          LastSeenResource.findRelatedLastSeen().$promise,
        resolvesFolders: Folder =>
          Folder.find({ filter: { include: ['user'] } }).$promise,
        filetype: () => 'none',
        extensions: () => '*',
        extendExtensions: () => null,
        mediatype: () => null,
        displayType: () => null,
        modelName: () => null,
        modelId: () => null
      }
    })
    .state('app.settings.certificate', {
      url: '/certificate/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsCertificateController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.certificate-mapping', {
      url: '/certificate-mapping/:id',
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsCertificateMappingController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedCertificate: ($stateParams, Certificate) =>
          Certificate.findOne({ filter: { where: { id: $stateParams.id } } })
            .$promise,
        ResolvedCertificateMapping: ($stateParams, CertificateMapping) =>
          CertificateMapping.find({
            filter: { where: { certificateId: $stateParams.id } }
          }).$promise
      }
    })
    .state('app.settings.custom-fields', {
      url: '/custom-fields/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsCustomFieldsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.connector', {
      url: '/connector/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsConnectorListController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.connector-new', {
      url: '/connector/new',
      views: {
        'content@app': {
          template: require('./connector/connector-new-edit/connector-new-edit.html'),
          controller: 'SettingsConnectorNewEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedConnector: () => null
      }
    })
    .state('app.settings.connector-edit', {
      url: '/connector/edit/:id',
      views: {
        'content@app': {
          template: require('./connector/connector-new-edit/connector-new-edit.html'),
          controller: 'SettingsConnectorNewEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedConnector: (Connector, $stateParams) =>
          Connector.findOne({ filter: { where: { id: $stateParams.id } } })
            .$promise
      }
    })
    .state('app.settings.connector-show', {
      url: '/connector/show/:id',
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsConnectorShowController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedConnector: (Connector, $stateParams) =>
          Connector.findOne({
            filter: {
              include: ['functions'],
              where: { id: $stateParams.id }
            }
          }).$promise
      }
    })
    .state('app.settings.replication-clients', {
      url: '/replication-clients/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsReplicationClientsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.replication-client-detail', {
      url: '/replication-client/:id',
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsReplicationClientHistoryController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedReplicationClient: ($stateParams, SyncClients) =>
          SyncClients.findOne({
            filter: {
              where: { id: $stateParams.id }
            }
          }).$promise
      }
    })
    .state('app.settings.category', {
      url: '/category/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsCategoryController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        allCategory: Category => Category.find({}).$promise
      }
    })
    .state('app.settings.translate', {
      url: '/translate/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'SettingsTranslateController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.information', {
      url: '/information/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedInfo: Setting => Setting.getSystemInfo().$promise
      },
      views: {
        'content@app': {
          template: require('./information/information.html'),
          controller: 'SettingsInformationController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.license', {
      url: '/license',
      resolve: {
        getUsersStats: Setting => {
          return Setting.getUsersStats().$promise
        },
        ResolvedLicense: Setting => {
          return new Promise(async resolve => {
            try {
              const setting = await Setting.privateSettings({
                settingNames: ['license']
              }).$promise
              resolve(setting)
            } catch (e) {
              resolve({
                license: {
                  expirationDate: new Date(),
                  method: 'perUser',
                  enforcementMethod: 'hard',
                  maxViewerCount: 0,
                  maxEditorCount: 0,
                  maxUserCount: 0,
                  enforceConcurrent: false,
                  enforceExpirationDate: false,
                  enforceExpirationDateOnSlaves: false
                }
              })
            }
          })
        }
      },
      views: {
        'content@app': {
          template: require('./license/license.template.html'),
          controller: 'SettingsLicenseController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.ai', {
      url: '/ai',
      views: {
        'content@app': {
          template: require('./ai/ai-settings.template.html'),
          controller: 'SettingsAIController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.replication', {
      url: '/replication/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedSettings: UpdateInfo =>
          UpdateInfo.findOne({
            filter: { where: { key: 'replication-settings' } }
          }).$promise
      },
      views: {
        'content@app': {
          template: require('./replication/replication.html'),
          controller: 'SettingsReplicationController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.internal-settings', {
      url: '/internal-settings',
      resolve: {
        ResolvedInternalSettings: Setting => {
          return Setting.privateSettings({
            settingNames: [
              'resetViaSMS',
              'enableSMS',
              'SMSBank',
              'usedSMS',
              'passwordComplexity'
            ]
          }).$promise
        }
      },
      views: {
        'content@app': {
          template: require('./internal-settings/internal-settings.template.html'),
          controller: 'InternalSettingsController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.licensing', {
      url: '/licensing',
      resolve: {
        getUsersStats: Setting => {
          return Setting.getUsersStats().$promise
        },
        ResolvedLicense: Setting => {
          return new Promise(async resolve => {
            try {
              const setting = await Setting.privateSettings({
                settingNames: ['license']
              }).$promise
              resolve(setting)
            } catch (e) {
              resolve({
                license: {
                  expirationDate: new Date(),
                  method: 'perUser',
                  enforcementMethod: 'hard',
                  maxViewerCount: 0,
                  maxEditorCount: 0,
                  maxUserCount: 0,
                  enforceConcurrent: false,
                  enforceExpirationDate: false
                }
              })
            }
          })
        }
      },
      views: {
        'content@app': {
          template: require('./licensing/licensing.template.html'),
          controller: 'SettingsLicensingController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.search-and-replace', {
      url: '/search-and-replace',
      resolve: {},
      views: {
        'content@app': {
          template: require('./search-and-replace/search-and-replace.template.html'),
          controller: 'SearchAndReplaceController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.authentication', {
      abstract: true,
      url: '/authentication'
    })
    .state('app.settings.authentication.ldap', {
      url: '/ldap',
      resolve: {
        ResolvedAuthenticationSettings: Setting =>
          Setting.privateSettings({
            settingNames: ['authentication-ldap', 'disableLocalUserCreation']
          }).$promise,
        ResolvedRoles: Role =>
          Role.find({
            filter: {
              where: {
                name: {
                  nin: [
                    '$authenticated',
                    '$unauthenticated',
                    '$everyone',
                    '$owner'
                  ]
                }
              },
              fields: { id: true, nicename: true, disableUse: true }
            }
          }).$promise.then(res =>
            res.map(role => ({
              id: role.id,
              name: role.nicename,
              notAnOption: role.disableUse
            }))
          ),
        ResolvedGroups: Group => {
          return Group.find({
            filter: {
              fields: { id: true, name: true }
            }
          }).$promise
        }
      },
      views: {
        'content@app': {
          template: require('./authentication/ldap/ldap.template.html'),
          controller: 'LdapAuthenticationController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.authentication.saml', {
      url: '/saml',
      resolve: {
        ResolvedAuthenticationSettings: Setting =>
          Setting.privateSettings({
            settingNames: ['authentication-saml', 'disableLocalUserCreation']
          }).$promise,
        ResolvedRoles: Role =>
          Role.find({
            filter: {
              where: {
                name: {
                  nin: [
                    '$authenticated',
                    '$unauthenticated',
                    '$everyone',
                    '$owner'
                  ]
                }
              },
              fields: { id: true, nicename: true, disableUse: true }
            }
          }).$promise.then(res =>
            res.map(role => ({
              id: role.id,
              name: role.nicename,
              notAnOption: role.disableUse
            }))
          ),
        ResolvedGroups: Group => {
          return Group.find({
            filter: {
              fields: { id: true, name: true }
            }
          }).$promise
        }
      },
      views: {
        'content@app': {
          template: require('./authentication/saml/saml.template.html'),
          controller: 'SamlAuthenticationController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.authentication.provisioning', {
      url: '/provisioning',
      resolve: {
        ResolvedProvisioningSettings: Setting =>
          Setting.privateSettings({
            settingNames: ['provisioning']
          }).$promise
      },
      views: {
        'content@app': {
          template: require('./provisioning/provisioning.template.html'),
          controller: 'SettingsProvisioningController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.password-strength', {
      url: '/password-strength',
      resolve: {
        ResolvedPasswordComplexeity: Setting =>
          Setting.privateSettings({
            settingNames: ['passwordComplexity']
          }).$promise
      },
      views: {
        'content@app': {
          template: require('./password-strength/password-strength.template.html'),
          controller: 'PasswordStrengthController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.password-reset', {
      url: '/password-reset',
      resolve: {
        ResolvedPasswordResetSettings: Setting =>
          Setting.privateSettings({
            settingNames: ['enableSMS', 'resetViaSMS']
          }).$promise
      },
      views: {
        'content@app': {
          template: require('./password-reset/password-reset.template.html'),
          controller: 'PasswordResetController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.ip-restriction', {
      url: '/ip-restriction',
      resolve: {
        ResolvedIpRestrictionSettings: Setting =>
          Setting.privateSettings({
            settingNames: ['ipRestriction']
          }).$promise
      },
      views: {
        'content@app': {
          template: require('./ip-restriction/ip-restriction.template.html'),
          controller: 'IpRestrictionController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.settings.file-parser-list', {
      url: '/file-parser/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedTables: Table =>
          Table.find({ filter: { fields: { id: true, name: true } } }).$promise
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'FileParsersController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'file-parser'
    })
    .state('app.settings.file-parser-show', {
      url: '/file-parser/show/?:id',
      views: {
        'content@app': {
          template: require('./file-parser/show/file-parser-show.html'),
          controller: 'FileParserShowController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'file-parser',
      resolve: {
        ResolvedTables: Table =>
          Table.find({ filter: { fields: { id: true, name: true } } }).$promise,
        ResolvedFileParser: (FileParser, $stateParams) => {
          if (!$stateParams.id || $stateParams.id === '') {
            return null
          }
          return FileParser.findOne({
            filter: { where: { id: $stateParams.id } }
          }).$promise
        }
      }
    })
    .state('app.settings.file-parser-job-list', {
      url: '/file-parser-jobs/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedFileParsers: FileParser =>
          FileParser.find({ filter: { fields: { id: true, name: true } } })
            .$promise
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'FileParserJobsController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'file-parser'
    })
    .state('app.settings.file-parser-strategies-job-list', {
      url: '/file-parser-strategies-jobs/:fileParserJobId/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedFileParsers: FileParser =>
          FileParser.find({ filter: { fields: { id: true, name: true } } })
            .$promise
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'FileParserStrategiesJobsController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'file-parser'
    })
    .state('app.settings.integrations', {
      url: '/integrations',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.dataviz.chart" */ 'root/node_modules/@progress/kendo-ui/js/kendo.dataviz.chart'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      resolve: {
        ResolvedIntegrations: function (Setting) {
          return Setting.getAvailableIntegrations().$promise
        }
      },
      views: {
        'content@app': {
          template: require('./integrations/integrations.index.html'),
          controller: 'IntegrationsIndexController'
        }
      }
    })
    .state('app.settings.integrations.single', {
      url: '/:integrationName/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'IntegrationsController'
        }
      },
      resolve: {
        integrationCheck: function (
          ResolvedIntegrations,
          $stateParams,
          $state
        ) {
          if (
            $stateParams.integrationName &&
            ResolvedIntegrations.find(
              integration =>
                integration.name === $stateParams.integrationName &&
                integration.available
            )
          ) {
            return true
          }
          return $state.go('app.404')
        },
        ResolvedUsers: UserModel =>
          UserModel.find({
            filter: { fields: { id: true, displayName: true } }
          }).$promise
      }
    })
    .state('app.settings.webhook', {
      abstract: true,
      url: '/webhook'
    })
    .state('app.settings.webhook.list', {
      url: '/list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WebhooksController'
        }
      },
      resolve: {},
      bodyClass: 'webhooks'
    })
    .state('app.settings.webhook.show', {
      url: '/show/?:id',
      views: {
        'content@app': {
          template: require('./webhook/webhook.show.template.html'),
          controller: 'WebhookShowController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'webhook webhook-show',
      resolve: {
        ResolvedWebhook: function (Webhook, $stateParams) {
          if (!$stateParams.id) return null
          return Webhook.findOne({
            filter: {
              where: { id: $stateParams.id },
              deleted: true
            }
          }).$promise
        }
      }
    })
    .state('app.settings.priority-label', {
      url: '/priority-label/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'PriorityLabelController'
        }
      }
    })
    .state('app.settings.priority-action', {
      url: '/priority-action/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'PriorityActionController'
        }
      },
      resolve: {
        ResolvedPrioritySettings: function (Priority) {
          return Priority.getPrioritySettings().$promise
        }
      }
    })
    .state('app.settings.priority', {
      url: '/priority/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./integrations/priority/priority.template.html'),
          controller: 'PriorityController'
        }
      },
      resolve: {
        ResolvedPrioritySettings: function (Priority) {
          return Priority.getPrioritySettings().$promise
        },
        ResolvedPriorityServiceCallStatuses: function (Priority) {
          return Priority.getPriorityServiceCallStatuses().$promise
        },
        ResolvedWorkorderCustomFields: function (CustomField) {
          return CustomField.getModelCustomField({ modelName: 'Workorder' })
            .$promise
        },
        ResolvedPartAssemblyCustomFields: function (CustomField) {
          return CustomField.getModelCustomField({ modelName: 'PartAssembly' })
            .$promise
        },
        ResolvedRevisionCustomFields: function (CustomField) {
          return CustomField.getModelCustomField({ modelName: 'Revision' })
            .$promise
        },
        ResolvedPriorityMetaData: function (Priority) {
          return Priority.getPriorityMetaData({ shouldManipulate: true })
            .$promise
        },
        ResolvedWarehouses: function (Priority) {
          return Priority.getWarehouses().$promise
        }
      }
    })
    .state('app.settings.nps-results', {
      url: '/nps-results/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedUsers: UserModel =>
          UserModel.find({
            filter: { fields: { id: true, displayName: true } }
          }).$promise
      },
      views: {
        'content@app': {
          template: require('./nps-results/nps-results.template.html'),
          controller: 'SettingsNpsResultsController'
        }
      }
    })
    .state('app.settings.release-notes', {
      url: '/release-notes',
      resolve: {
        ResolvedReleaseNotes: Setting => {
          return Setting.getReleaseNotes().$promise
        }
      },
      views: {
        'content@app': {
          template: require('./release-notes/release-notes.template.html'),
          controller: 'ReleaseNotesController',
          controllerAs: 'vm'
        }
      }
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.settings', {
    title: 'SETTINGS.SETTINGS',
    icon: 'icon-cog',
    weight: 900,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
  msNavigationServiceProvider.saveItem('apps.help-center', {
    title: 'SETTINGS.HELP_CENTER',
    icon: 'icon-help-circle',
    weight: 1000,
    state:
      'app.pages.external({externalUrl:"https://success.nextplus.io",target:"_blank"})'
  })
  // SYSTEM
  msNavigationServiceProvider.saveItem('apps.settings.system', {
    title: 'SETTINGS.SYSTEM',
    icon: 'icon-cog',
    state: 'app.settings.system',
    weight: 100,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
  // SERVER SUB MENU
  msNavigationServiceProvider.saveItem('apps.settings.server', {
    title: 'SETTINGS.SERVER',
    icon: 'icon-cog-transfer-outline',
    weight: 20,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
  // SECURITY SUB MENU
  msNavigationServiceProvider.saveItem('apps.settings.security', {
    title: 'SETTINGS.SECURITY',
    icon: 'icon-shield-alert',
    weight: 30,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
  // UI SUB MENU
  msNavigationServiceProvider.saveItem('apps.settings.ui', {
    title: 'SETTINGS.UI',
    icon: 'icon-auto-fix',
    weight: 40,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
  // FIle manager
  msNavigationServiceProvider.saveItem('apps.settings.upload', {
    title: 'SETTINGS.FILE_MANAGER',
    icon: 'icon-file-find',
    state: 'app.settings.upload',
    hidden: true,
    weight: 50,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
  msNavigationServiceProvider.saveItem('apps.settings.archive', {
    title: 'SETTINGS.ARCHIVE',
    icon: 'icon-archive',
    state: 'app.settings.archive',
    hidden: true,
    weight: 60,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  // FILE-PARSER SUB MENU
  msNavigationServiceProvider.saveItem('apps.modeling.file-parser', {
    title: 'FILE_PARSER.FILE_PARSER',
    icon: 'icon-file-sync-outline',
    weight: 120,
    acls: [{ model: 'FileParser', method: 'create' }]
  })

  // SERVER - LICENSING
  msNavigationServiceProvider.saveItem('apps.settings.server.licensing', {
    title: 'SETTINGS.LICENSING',
    state: 'app.settings.licensing',
    weight: 1,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
  // RELEASE_NOTES
  msNavigationServiceProvider.saveItem('apps.settings.server.release-notes', {
    title: 'SETTINGS.RELEASE_NOTES.TITLE',
    state: 'app.settings.release-notes',
    weight: 10,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
  // SERVER - STATUS
  msNavigationServiceProvider.saveItem('apps.settings.server.status', {
    title: 'SETTINGS.SERVER_STATUS',
    state: 'app.settings.information',
    hidden: true,
    weight: 20,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.server.ai', {
    title: 'SETTINGS.AI_SETTINGS',
    state: 'app.settings.ai',
    weight: 25,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.server.priority', {
    title: 'PRIORITY.TITLE',
    state: 'app.settings.priority',
    weight: 25,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.server.interfaces', {
    title: 'INTEGRATIONS.INTERFACES',
    state: 'app.settings.integrations',
    weight: 30,
    acls: [{ model: 'Setting', method: 'getIntegrationList' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.server.webhooks', {
    title: 'SETTINGS.WEBHOOKS.TITLE',
    state: 'app.settings.webhook.list',
    weight: 35
  })
  // SERVER - EVENTS
  msNavigationServiceProvider.saveItem('apps.settings.server.events', {
    title: 'SETTINGS.EVENTS.TITLE',
    state: 'app.settings.events',
    hidden: true,
    weight: 40,
    acls: [{ model: 'Event', method: 'find' }]
  })
  // SERVER - SEARCH AND REPLACE
  msNavigationServiceProvider.saveItem(
    'apps.settings.server.search-and-replace',
    {
      title: 'SETTINGS.SEARCH_AND_REPLACE.TITLE',
      state: 'app.settings.search-and-replace',
      weight: 50,
      hidden: false,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )

  // SERVER - REPLICATION
  msNavigationServiceProvider.saveItem('apps.settings.server.replication', {
    title: 'SETTINGS.REPLICATION',
    hidden: true,
    weight: 60,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  // SERVER - REPLICATION
  msNavigationServiceProvider.saveItem(
    'apps.settings.server.replication.replication-settings',
    {
      title: 'SETTINGS.REPLICATION_SETTINGS',
      state: 'app.settings.replication',
      hidden: false,
      weight: 1,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )
  // SERVER - REPLICATION-CLIENTS
  msNavigationServiceProvider.saveItem(
    'apps.settings.server.replication.replication-clients',
    {
      title: 'SyncClients.MENU-TITLE',
      hidden: false,
      state: 'app.settings.replication-clients',
      weight: 2,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )
  // SERVER - EXPORT SUB MENU
  msNavigationServiceProvider.saveItem('apps.settings.server.export', {
    title: 'SETTINGS.EXPORT/IMPORT',
    weight: 80,
    hidden: false,
    acls: [{ model: 'Setting', method: 'exportModels' }]
  })
  // SERVER - EXPORT - EXPORT
  msNavigationServiceProvider.saveItem('apps.settings.server.export.export', {
    title: 'SETTINGS.MODEL_EXPORT',
    state: 'app.settings.export',
    weight: 2,
    hidden: false,
    acls: [{ model: 'Setting', method: 'exportModels' }]
  })
  // SERVER - EXPORT - IMPORT
  msNavigationServiceProvider.saveItem('apps.settings.server.export.import', {
    title: 'SETTINGS.MODEL_IMPORT',
    state: 'app.settings.import',
    weight: 3,
    hidden: false,
    acls: [{ model: 'Setting', method: 'importModelsFile' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.user.roles', {
    title: 'SETTINGS.ROLES_CONFIGURATION',
    state: 'app.settings.roles',
    weight: 10,
    acls: [{ model: 'Role', method: 'create' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.group', {
    title: 'SETTINGS.GROUPS',
    icon: 'icon-group',
    state: 'app.settings.group',
    weight: 90,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.break-type', {
    title: 'SETTINGS.BREAKS.BREAK_TYPES',
    icon: 'icon-pause-octagon-outline',
    state: 'app.settings.break-type',
    weight: 100,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.connector', {
    title: 'SETTINGS.CONNECTOR',
    icon: 'icon-memory',
    state: 'app.settings.connector',
    weight: 9,
    hidden: true,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.category', {
    title: 'SETTINGS.CATEGORIES',
    icon: 'icon-arrange-bring-to-front',
    state: 'app.settings.category',
    weight: 80,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.user.certificate', {
    title: 'SETTINGS.CERTIFICATE',
    state: 'app.settings.certificate',
    weight: 70,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.ui.translate', {
    title: 'SETTINGS.TRANSLATE',
    state: 'app.settings.translate',
    weight: 1,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
  msNavigationServiceProvider.saveItem('apps.settings.ui.pages', {
    title: 'PAGES.PAGES_MANAGEMENT',
    weight: 10,
    state: 'app.pages.list',
    acls: [{ model: 'Pages', method: 'create' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.ui.custom-field', {
    title: 'CUSTOM_FIELDS.CUSTOM_FIELDS',
    state: 'app.settings.custom-fields',
    weight: 20,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })

  msNavigationServiceProvider.saveItem('apps.settings.ui.menus', {
    title: 'MENU-MANAGEMENT.MENU-MANAGEMENT',
    weight: 30,
    state: 'app.menus.list',
    acls: [{ model: 'Menus', method: 'create' }]
  })

  msNavigationServiceProvider.saveItem(
    'apps.settings.security.Ip_whitelisting',
    {
      title: 'SETTINGS.IP_WHITELISTING',
      state: 'app.settings.ip-restriction',
      weight: 1,
      hidden: true,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )

  msNavigationServiceProvider.saveItem(
    'apps.settings.security.password-policy',
    {
      title: 'SETTINGS.PASSWORD_POLICY',
      state: 'app.settings.password-strength',
      weight: 10,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )

  msNavigationServiceProvider.saveItem(
    'apps.settings.security.password-reset',
    {
      title: 'SETTINGS.PASSWORD_RESET',
      state: 'app.settings.password-reset',
      weight: 20,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )

  msNavigationServiceProvider.saveItem(
    'apps.settings.security.authentication',
    {
      title: 'SETTINGS.AUTHENTICATION.AUTHENTICATION',
      weight: 30,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )
  msNavigationServiceProvider.saveItem(
    'apps.settings.security.authentication.ldap',
    {
      title: 'SETTINGS.AUTHENTICATION.LDAP_PROVIDER',
      state: 'app.settings.authentication.ldap',
      weight: 30,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )
  msNavigationServiceProvider.saveItem(
    'apps.settings.security.authentication.saml',
    {
      title: 'SETTINGS.AUTHENTICATION.SAML_PROVIDER',
      state: 'app.settings.authentication.saml',
      weight: 30,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )

  msNavigationServiceProvider.saveItem(
    'apps.settings.security.authentication.provisioning',
    {
      title: 'SETTINGS.PROVISIONING_SCIM.TITLE',
      state: 'app.settings.authentication.provisioning',
      weight: 40,
      acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
    }
  )

  msNavigationServiceProvider.saveItem('apps.modeling.file-parser.list', {
    title: 'FILE_PARSER.PARSERS',
    state: 'app.settings.file-parser-list',
    weight: 1,
    acls: [{ model: 'FileParser', method: 'create' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.file-parser.job-list', {
    title: 'FILE_PARSER_JOB.RUNNERS',
    state: 'app.settings.file-parser-job-list',
    weight: 2,
    acls: [{ model: 'FileParser', method: 'create' }]
  })
}
